import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import { useCartHook } from './useCartHook';
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { theme } from '../../theme/index';
import { AdvBanner } from '../../components/AdvBanner';
import styled from '@emotion/styled';
import { FiTruck } from 'react-icons/fi';
import { CartValueSection } from './cart/CartValueSection';
import { CartProductCard } from './cart/productCard/CartProductCard';
import { FaRegTrashCan } from 'react-icons/fa6';
import { useConfiguration } from '../../hooks/useConfiguration';
import { CartEmptySection } from './cart/emptyCart/CartEmptySection';

export const CartRoute = () => {
  const {
    totalCartValue,
    totalItemsInCart,
    checkoutMessage,
    handleCart,
    checkout,
  } = useCartHook();
  const { storeId = '', harvestId = '' } = useParams();
  const navigate = useNavigate();
  const { LanguageVersion, Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const currency = Currency || '$';
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: { defaultLanguage: LanguageVersion },
  });
  const {
    value: { cart, termId },
  } = useAppContext();
  const { data } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId: termId?.split('__')[0] })
  );

  const edges = data?.getEPogTermListing?.edges;
  let TermBannerImage,
    TermBannerProducts,
    TermBannerSponsoredBy,
    TermBannerText;
  if (termId && edges?.length > 0) {
    TermBannerImage = edges[0]?.node?.TermBannerImage;
    TermBannerProducts = edges[0]?.node?.TermBannerProducts;
    TermBannerSponsoredBy = edges[0]?.node?.TermBannerSponsoredBy;
    TermBannerText = edges[0]?.node?.TermBannerText;
  }

  if (checkoutMessage && window.location.pathname.indexOf('checkout') === -1) {
    navigate(window.location.pathname + '/checkout');
  }

  if (totalItemsInCart > 0) {
    return (
      <>
        {TermBannerProducts && (
          <div style={{ marginTop: '65px' }}>
            <AdvBanner
              TermBannerImage={TermBannerImage}
              TermBannerProducts={TermBannerProducts}
              TermBannerSponsoredBy={TermBannerSponsoredBy}
              TermBannerText={TermBannerText}
              Currency={currency}
            />
          </div>
        )}
        <div>
          <CartValueSection totalCartValue={totalCartValue} />
          <div>
            <ShippingHeader>
              <ShippingInfo>
                <FiTruck size={20} color={theme.samsclub.primary} />
                <ShippingText>Shipping items ({totalItemsInCart})</ShippingText>
              </ShippingInfo>
              <SaveForLaterText>Save for later</SaveForLaterText>
            </ShippingHeader>
            {cart?.map((product: IProduct, index: number) => (
              <ComponentWrapper key={index}>
                <ProductWrapper>
                  <CartProductCard isLast={true} product={product} />
                </ProductWrapper>
                <DeleteButton onClick={() => handleCart('removeAll', product)}>
                  <FaRegTrashCan size={20} />
                  <ButtonText>Remove</ButtonText>
                </DeleteButton>
              </ComponentWrapper>
            ))}
          </div>
        </div>
        <CenteredContainer>
          <CheckoutButton onClick={() => checkout('checkout', cart)}>
            <CheckoutText>Checkout</CheckoutText>
          </CheckoutButton>
        </CenteredContainer>
      </>
    );
  } else {
    return <CartEmptySection checkoutMessage={checkoutMessage} />;
  }
};

const ShippingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const ShippingInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const ShippingText = styled.div`
  margin-left: 8px;
`;

const SaveForLaterText = styled.div`
  font-size: 14px;
  color: ${theme.samsclub.primary};
`;

const ComponentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
`;

const ProductWrapper = styled.div`
  width: 70%;
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  color: ${theme.samsclub.primary};
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonText = styled.span`
  margin-left: 5px;
`;

const EmptyCartMessage = styled.div`
  text-align: center;
  font-size: 20px;
  margin-top: 120px;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  height: 60px;
`;

const CheckoutButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: ${theme.samsclub.primary};
`;

const CheckoutText = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${theme.samsclub.white};
`;

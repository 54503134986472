import React from "react";
import styled from "@emotion/styled";
import { IProduct } from "../../types";
import { Link, useParams } from "react-router-dom";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { theme } from "../../theme";
import { AddToCartButton } from "../AddToCartButton";
import { useConfiguration } from "../../hooks/useConfiguration";
import Rating from "@mui/material/Rating";

interface IProps {
  product: IProduct;
  onClick: string;
  url: string;
}

export const SimilarProductItem = ({ product, onClick, url }: IProps) => {
  const { storeId = "", harvestId = "" } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const currency = Currency ? Currency : "$";
  const isCurrectLocationCart = window.location.pathname.includes("cart");

  const productPriceDifference =
    product.Price > 0 ? product.RegularPrice - product.Price : null;

  return (
    <LinkStyled to={onClick}>
      <SimilarProductStyled key={product.id}>
        {product.Sponsored ? (
          <SponsoredStyled>Sponsored</SponsoredStyled>
        ) : (
          <SponsoredPlaceholder />
        )}
        <ImageStyled
          src={`${imgSrcByEnv()}${
            product?.Gallery && product?.Gallery[0]?.image?.fullpath
          }`}
          alt="Product"
        />
        <NameStyled>{product.Name}</NameStyled>

        {isCurrectLocationCart && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Rating
              name="simple-controlled"
              value={product.RROverall}
              sx={{
                "& .MuiRating-iconFilled": {
                  color: "black",
                },
                "& .MuiRating-iconHover": {
                  color: "black",
                },
                "& .MuiRating-iconEmpty": {
                  color: "black",
                },
              }}
            />
            <p style={{ fontSize: "14px", marginLeft: "4px" }}>
              {product.RRAmount}
            </p>
          </div>
        )}

        <PriceStyled>
          {productPriceDifference ? (
            <p>
              {currency}
              {productPriceDifference} off {currency}
              {product.RegularPrice}
            </p>
          ) : (
            <Placeholder />
          )}
          <p>
            {currency}
            {product.Price > 0 ? product.Price : product.RegularPrice}
          </p>
        </PriceStyled>

        <AddToCartButton product={product} url={url} />
      </SimilarProductStyled>
    </LinkStyled>
  );
};

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${theme.target.black};
`;

const SimilarProductStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 41vw;
  min-width: 41vw;
  padding: 16px;
  color: black;
  font-size: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  & > img {
    width: 100%;
    object-fit: cover;
  }
`;

export const SponsoredStyled = styled.p`
  color: ${theme.samsclub.grey};
`;

const ImageStyled = styled.img`
  margin-top: 10px;
  border-radius: 8px;
  height: 150px; /* Set a fixed height */
  object-fit: cover; /* Ensure the image covers the entire area while maintaining its aspect ratio */
  width: 100%; /* Ensure the image takes up the full width of its container */
`;

const NameStyled = styled.p`
  display: -webkit-box;
  min-height: 40px;
  margin-top: 10px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

const PriceStyled = styled.div`
  margin: 12px 0;
  font-weight: 700;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SponsoredPlaceholder = styled.div`
  height: 15px;
`;

const Placeholder = styled.div`
  height: 20px;
`;

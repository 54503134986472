import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { ModalTypes } from '../Modals/types';
import { Chip } from '../Chip';
import { IProduct } from '../../types';
import { useFilters } from './useFilters';
import {
  FiltersStyled,
  Header,
  PopularFiltersStyled,
  ChipsContainer
} from './styles';
// import {
//   FilterName,
//   IAddProductButtonFunctionality,
//   IFilters,
//   IOptionButtonVisibilty,
//   ProductFieldName,
// } from '../../types/config';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import { ReactSVG } from 'react-svg';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { contentSquarePageEvent } from '../../helpers'
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
// import { ProductCard } from '../ProductCard';
import { ChipWithImage } from '../ChipWithImage';

export const PopularFilters = ({
  products,
  categories,
  brands,
  productsBeforeFiltering
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
  categories: any;
  brands: string[];
}) => {
  const {
    value: { productsSorting, searchFilters },
    dispatch,
  } = useAppContext();

  const { isCustomFilter, isPriceFilter, isBrandsFilter, isCategoriesFilter, isSpecialOffersFilter, handleFiltersVisibility } = useFilters(
    {
      products,
      productsBeforeFiltering,
    }
  );

  const navigate = useNavigate();

  const { storeId = '', termId, harvestId = '' } = useParams();

  const { LanguageVersion, FilterType } = useConfiguration({ storeId, harvestId });

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    })
  );

  
  // const {
  //   PillFilters,
  //   Currency,
  //   ProductFields,
  //   AddPosition,
  //   ProductCardBorderType,
  //   OptionButtonVisibility,
  //   AddProductButtonFunctionality,
  // } = useConfiguration({
  //   storeId,
  //   harvestId,
  // });

  const brandFilter = searchFilters.find(
    (filter: any) => filter.name === 'Brand'
  );

  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === 'Rating'
  );

  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );

  // const specialOffersFilter = searchFilters.find(
  //   (filter: any) => filter.name === 'Special Offers'
  // );

    const edges = data?.getEPogTermListing?.edges;
  
    let filters = edges
        .map((edge: any) => edge?.node?.PillFilters)
        .flat()
    let filtersWithImage = edges
        .map((edge: any) => edge?.node?.PillFiltersWithImage)
        .flat();

  return (
    <FiltersStyled>
      {(products?.length || (!products?.length && searchFilters?.length)) && FilterType ? <Header>{translation?.getLocalization.PopularFilters}</Header> : ''}
      <PopularFiltersStyled isLessThanSixItems={categories?.length}>
        {categories?.map((category: any, index: number) => (
          (category?.TermImage?.fullpath) ? 
          <div
            className="filter"
            key={index}
            onClick={() => 
              navigate(
                `/${storeId}/${harvestId}/category/${
                  category.id
                }__${category.key.replaceAll(' ', '_')}`
              )
            }
          >
            <img
              src={
                category?.TermImage?.fullpath
                  ? `${imgSrcByEnv()}${category?.TermImage?.fullpath}`
                  : '/files/generic/img/square.svg'
              }
              alt="carousel"
            />
            {/* <img src="/files/generic/img/square.svg" alt="" /> */}
            <p> {category?.key}</p>
          </div> : ''
        ))}
      </PopularFiltersStyled>

      {(products.length || (!products?.length && searchFilters?.length)) && 
       (FilterType == "Plain" || FilterType =="All") ? 
        filters.length > 1 
        &&
        <ChipsContainer>
          <Chip
            title={translation?.getLocalization.AllFilters}
            addinitonalIcon={
              <ReactSVG src="/files/generic/img/allFilters.svg" />
            }
            onClick={() => {
              handleFiltersVisibility();
              contentSquarePageEvent('allFilters')
            }}
            isExpand={false}
            background="#DBDBDB"
          />

          <Chip
            title={translation?.getLocalization.SortBy}
            addinitonalIcon={<ReactSVG src="/files/generic/img/sortBy.svg" />}
            colors={productsSorting.length ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('sortByFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.sorting,
                },
              });
            }}
            isExpand={false}
            background="#DBDBDB"
          />
          {(Object.values(filters).map((value, index: number) => {
          if (filters[index].Name == 'Price') {
          return <Chip
            title={translation?.getLocalization.Price}
            colors={isPriceFilter ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('priceFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.priceRange,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />
          } if (filters[index].Name == 'Rating') {
          return <Chip
            title={translation?.getLocalization.Rating}
            colors={ratingFilter?.value ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('ratingFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.rating,
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />
          } if (filters[index].Name == 'Variants') {
          return <Chip
            title={translation?.getLocalization.Variants}
            colors={variantsFilter?.value ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('variantFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.variants,
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />
          } if (filters[index].Name == 'Categories') {
          return <Chip
            title={translation?.getLocalization.Categories}
            onClick={() => {
              contentSquarePageEvent('categoriesFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.categories,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            colors={isCategoriesFilter ? 'dark' : 'light'}
            isExpand={false}
            background="#ffffff"
          />
          } if (filters[index].Name == 'Special Offers') {
          return <Chip
            title={translation?.getLocalization.SpecialOffers}
            colors={isSpecialOffersFilter ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('specialOffersFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.specialOffers,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />
          } if (filters[index].Name == 'Brand') {
          return <Chip
            title={translation?.getLocalization.Brand}
            onClick={() => {
              contentSquarePageEvent('brandSelectFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.brands,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            colors={isBrandsFilter ? 'dark' : 'light'}
            isExpand={false}
            background="#ffffff"
          />
          }
          {brands?.length > 1 &&
            brands?.map((brand: string) => {
              const isChecked = brandFilter?.brands[brand] || false;
              return (
                <Chip
                  key={brand}
                  title={brand}
                  onClick={() => {
                    contentSquarePageEvent('brandFilter_' + brand)
                    dispatch({
                      type: actions.ADD_SEARCH_FILTER,
                      payload: {
                        name: 'Brand',
                        brands: { ...brandFilter?.brands, [brand]: !isChecked },
                      },
                    });
                  }}
                  colors={isChecked ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            })}
          } 
          )
          )}
        </ChipsContainer> : <></>
      }
      {(products.length || (!products?.length && searchFilters?.length)) &&
        (FilterType == "Image" || FilterType == "All") ?

        // Image Filters section 
        filtersWithImage.length > 1 &&
          <ChipsContainer>
            {(Object.values(filtersWithImage).map((value, index: number) => {
              if (filtersWithImage[index].Name == 'Price') {
                return <ChipWithImage
                  title={translation?.getLocalization.Price}
                  colors={isPriceFilter ? 'dark' : 'light'}
                  backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    contentSquarePageEvent('priceFilter')
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.priceRange,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  isExpand={false}
                  background="#ffffff"
                />
              } if (filtersWithImage[index].Name == 'Rating') {
                return <ChipWithImage
                  title={translation?.getLocalization.Rating}
                  colors={ratingFilter?.value ? 'dark' : 'light'}
                  backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    contentSquarePageEvent('ratingFilter')
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.rating,
                      },
                    });
                  }}
                  isExpand={false}
                  background="#ffffff"
                />
              } if (filtersWithImage[index].Name == 'Variants') {
                return <ChipWithImage
                  title={translation?.getLocalization.Variants}
                  colors={variantsFilter?.value ? 'dark' : 'light'}
                  backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    contentSquarePageEvent('variantFilter')
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.variants,
                      },
                    });
                  }}
                  isExpand={false}
                  background="#ffffff"
                />
              } if (filtersWithImage[index].Name == 'Categories') {
                return <ChipWithImage
                  title={translation?.getLocalization.Categories}
                  backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    contentSquarePageEvent('categoriesFilter')
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.categories,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  colors={isCategoriesFilter ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              } if (filtersWithImage[index].Name == 'Special Offers') {
                return <ChipWithImage
                  title={translation?.getLocalization.SpecialOffers}
                  colors={isSpecialOffersFilter ? 'dark' : 'light'}
                  backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    contentSquarePageEvent('specialOffersFilter')
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.specialOffers,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  isExpand={false}
                  background="#ffffff"
                />
              } if (filtersWithImage[index].Name == 'Brand') {
                return <ChipWithImage
                  title={translation?.getLocalization.Brand}
                  backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    contentSquarePageEvent('brandSelectFilter')
                    dispatch({
                      type: actions.TOGGLE_MODAL,
                      payload: {
                        isModal: true,
                        modalType: ModalTypes.brands,
                        modalContent: { productsBeforeFiltering },
                      },
                    });
                  }}
                  colors={isBrandsFilter ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              } else {
                return <ChipWithImage
                  key={'Custom_' + filtersWithImage[index].Name}
                  title={filtersWithImage[index].Name}
                  backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                  onClick={() => {
                    //contentSquarePageEvent('brandSelectFilter')
                    dispatch({
                      type: actions.ADD_SEARCH_FILTER,
                      payload: {
                        name: 'Custom_' + filtersWithImage[index].Name,
                        value: filtersWithImage[index].Name,
                        checked: isCustomFilter.includes('Custom_' + filtersWithImage[index].Name) ? false : true,
                      },
                    });
                  }}
                  colors={isCustomFilter.includes('Custom_' + filtersWithImage[index].Name) ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              }

              {
                brands?.length > 1 &&
                brands?.map((brand: string) => {
                  const isChecked = brandFilter?.brands[brand] || false;
                  return (
                    <ChipWithImage
                      key={brand}
                      title={brand}
                      backgroundImage={imgSrcByEnv() + filtersWithImage[index].Thumbnail?.fullpath}
                      onClick={() => {
                        contentSquarePageEvent('brandFilter_' + brand)
                        dispatch({
                          type: actions.ADD_SEARCH_FILTER,
                          payload: {
                            name: 'Brand',
                            brands: { ...brandFilter?.brands, [brand]: !isChecked },
                          },
                        });
                      }}
                      colors={isChecked ? 'dark' : 'light'}
                      isExpand={false}
                      background="#ffffff"
                    />
                  );
                })
              }


            }))}
          </ChipsContainer>:<></>
      }
    </FiltersStyled>
  );
      
};

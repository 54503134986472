import { useCartHook } from "./../useCartHook";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { theme } from "../../../theme";
import styled from "@emotion/styled";
import { useAppContext } from "../../../context";

export const CartValueSection = ({
  totalCartValue,
}: {
  totalCartValue: any;
}) => {
  const {
    value: { cart },
  } = useAppContext();

  const getTotalQuantity = () => {
    return cart.reduce((total: any, item: any) => total + item.amount, 0);
  };
  const oneItemInCart = getTotalQuantity() === 1;

  return (
    <ComponentWrapper>
      <Section>
        <Title>Total</Title>
        <ItemList>
          <ItemRow>
            <ItemText>
              Subtotal ({getTotalQuantity()} {oneItemInCart ? "item" : "items"})
            </ItemText>
            <ItemText>${totalCartValue}</ItemText>
          </ItemRow>
          <ItemRow>
            <ItemText>Shipping costs</ItemText>
            <ItemText>-</ItemText>
          </ItemRow>
          <ItemRow>
            <ItemColumn>
              <ItemText>Est. sales tax</ItemText>
              <SmallText>(Calculated in checkout)</SmallText>
            </ItemColumn>
            <ItemText>-</ItemText>
          </ItemRow>
          <Divider />
          <ItemRow>
            <ItemText>Est. total</ItemText>
            <ItemText>${totalCartValue}</ItemText>
          </ItemRow>
        </ItemList>
      </Section>
      <GreyDivider />
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div`
  background-color: ${theme.samsclub.white};
  z-index: 1;
  margin-top: 70px;
`;

const Section = styled.div`
  padding: 16px 12px;
  background-color: ${theme.samsclub.white};
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemText = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.p`
  font-size: 8px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${theme.samsclub.greyLight};
`;

const SavingsSection = styled.div`
  text-align: center;
  padding: 14px 0px;
  background-color: ${theme.samsclub.white};
`;

const SavingsText = styled.p`
  font-size: 14px;
  font-weight: 500;
  padding: 18px;
  color: ${theme.samsclub.red?.dark};
  background-color: ${theme.samsclub.blue?.light};
`;

const GreyDivider = styled.div`
  height: 6px;
  background-color: ${theme.samsclub.greyLight};
`;

const MembershipBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.samsclub.greyLight};
  background-color: ${theme.samsclub.blue?.light};
`;

const MembershipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MembershipText = styled.p`
  font-size: 17px;
  margin-left: 8px;
  font-weight: 800;
  color: ${theme.samsclub.primary};
`;

const ItalicText = styled.span`
  font-style: italic;
`;

const MembershipDescription = styled.p`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
`;

import { PopularProducts } from "../../components/PopularProducts";

export const HomeProductsSlider = ({
  shuffledProducts,
  title,
}: {
  shuffledProducts: any;
  title: string;
}) => {
  const productsData = title.includes("Trending")
    ? shuffledProducts
    : shuffledProducts;

  return (
    <>
      <PopularProducts products={productsData} title={title}></PopularProducts>
    </>
  );
};

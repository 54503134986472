interface IMemberPerksCard {
  iconSrc: string;
  alt: string;
  description: string;
}

export const HomeMemberPerksCard = ({
  iconSrc,
  alt,
  description,
}: IMemberPerksCard) => (
  <div>
    <img src={iconSrc} alt={alt} />
    <p>{description}</p>
  </div>
);

import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { theme } from "../../theme";
export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #000000;
`;

export const ProductCartStyled = styled.div`
  display: flex;
  max-width: 100vw;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ImageWrapperContainer = styled.div`
  position: relative;
  width: 40%;
  height: 200px;
  overflow: hidden;
  padding: 20px;
`;
export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;
`;
export const PromoLabelStyled = styled.p`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  color: ${theme.samsclub.white};
  background-color: ${theme.samsclub.red?.light};
`;
export const DescriptionContainer = styled.div`
  width: 60%;
  position: relative;
  padding-right: 30px;
  padding-bottom: 20px;
  p {
    font-weight: 500;
    font-family: "Montserrat";
  }
`;
export const SponsoredStyled = styled.p`
  color: ${theme.samsclub.grey};
`;
export const NameStyled = styled.p`
  margin-top: 10px;
  line-height: 14px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;
export const RatingStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  .MuiRating-iconFilled {
    color: ${theme.samsclub.black};
  }
  p {
    margin-left: 3px;
    font-size: 13px;
  }
`;
export const PriceStyled = styled.div`
  margin-top: 6px;

  & > div {
    font-size: 20px;
    font-weight: 500;
    .oldPrice {
      font-size: 13px;
      font-weight: 400;
      color: ${theme.samsclub.greyDark};
      text-decoration: line-through;
    }
  }
`;

export const PricePerUnitStyled = styled.p`
  margin-top: 5px;
  color: ${theme.samsclub.grey};
`;

export const FreeShippingStyled = styled.p`
  margin-top: 10px;
  & > span {
    color: ${theme.samsclub.steelblue};
    font-weight: 500;
    font-style: italic;
  }
`;
export const ButtonContainerStyled = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-20%, -50%);
`;

export const ShippingMethodStyled = styled.button<{
  isActive?: boolean;
}>`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: 5px;
  border: 1px solid ${theme.target.grey};
  cursor: pointer;
  border-radius: 5px;
  background-color: ${({ isActive }) =>
    isActive ? `${theme.target.greyLight}` : `${theme.target.white}`};
  box-shadow: ${({ isActive }: any) =>
    isActive
      ? `0 0 0 0.125rem ${theme.target.green}`
      : `0 0 0 0.0625rem ${theme.target.white}`};
  border-radius: 5px;
  width: 250px;

  &:hover {
    background-color: ${theme.target.greyLight};
  }
  .icon {
    font-size: 24px;
    margin: 10px 0;
  }

  .name {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
  }
  svg {
    color: ${theme.target.primary};
  }
  .description {
    text-align: left;
    font-size: 12px;
    color: ${({ isActive }) =>
      isActive ? `${theme.target.green}` : `${theme.target.greyDark}`};
    white-space: normal;
    word-wrap: break-word;
  }
`;

import React, { useState } from "react";
import { IProduct } from "../../types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../context";
import { useConfiguration } from "../../hooks/useConfiguration";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED, GET_PRODUCT_CATEGORY_BY_TERM } from "../../queries";
import { PdpGallery } from "../PdpGallery";
import { ProductVariants } from "../ProductVariant";
import {
  PdpCardStyled,
  PlusMemberContainer,
  PromoLabel,
  SponsoredContainer,
} from "./styles";
import { ShippingMethod } from "./ShippingMethod";
import { Rating } from "@mui/material";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { AdvBanner } from "../AdvBanner";
import { SponsoredStyled } from "../ProductCard/styles";
import { normalizeNameForUrl } from "../../helpers";
import { AddToCartButton } from "../AddToCartButton";

export const PdpCard = ({ product }: { product: IProduct }) => {
  const [newAmount, setNewAmount] = React.useState(0);
  const [prevKey, setPrevKey] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { storeId = "", categoryId, harvestId = "", productId } = useParams();
  const {
    dispatch,
    value: { cart, termId },
  } = useAppContext();
  const { Currency, ShipmentMethod } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split("__")[0],
    })
  );
  const edges = data?.getEPogTermListing?.edges;
  let TermBannerImage,
    TermBannerProducts,
    TermBannerSponsoredBy,
    TermBannerText;
  if (termId && edges?.length > 0) {
    TermBannerImage = edges[0]?.node?.TermBannerImage;
    TermBannerProducts = edges[0]?.node?.TermBannerProducts;
    TermBannerSponsoredBy = edges[0]?.node?.TermBannerSponsoredBy;
    TermBannerText = edges[0]?.node?.TermBannerText;
  }

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const currency = Currency || "$";

  const [shippingMethodSelected, setShippingMethodSelected] = React.useState(
    {}
  );

  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit != "0"
      ? product.PricePerUnit
      : "";

  const shipmentMethods = React.useMemo(() => {
    return (
      ShipmentMethod?.map((item) => ({
        icon: item.Name,
        name: item.Name,
        description: item.Name,
      })) || []
    );
  }, [ShipmentMethod]);

  React.useEffect(() => {
    setShippingMethodSelected(shipmentMethods?.[0]?.name.toLowerCase());
  }, [shipmentMethods]);

  const productInCart = cart.find(
    (productInCart: IProduct) => product.id === productInCart?.id
  );

  React.useEffect(() => {
    setNewAmount(productInCart?.amount || 1);
  }, [cart, product]);

  React.useEffect(() => {
    if (
      location.state?.prevPath.split("_cartadd")[0] ===
      location.pathname.split("_cartadd")[0]
    ) {
      setPrevKey(location.state.prevKey);
    }
  }, [location]);
  React.useEffect(() => {
    setPrevKey(location.key);
  }, []);

  const { id, Name, Price, RegularPrice, Brand, URLPromoCode } = product;
  const price = Price !== 0 ? Price : RegularPrice;
  const brand = Brand ? Brand : "";
  let promoCode;
  if (URLPromoCode != null) {
    promoCode = "__promoCode-" + URLPromoCode;
  } else {
    promoCode = "__promoCode-";
  }
  const url = categoryId
    ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
        Name
      )}_${price}_${promoCode}`
    : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
        Name
      )}_${price}_${promoCode}`;

  return (
    <PdpCardStyled>
      <p>{product.Name}</p>
      <div className="rating">
        <Rating
          name="half-rating-read"
          defaultValue={product.RROverall || 0}
          precision={0.5}
          readOnly
        />
        <span>({product.RRAmount || 0})</span>
      </div>
      <SponsoredContainer>
        {product.Sponsored && <SponsoredStyled>Sponsored</SponsoredStyled>}
      </SponsoredContainer>
      <PdpGallery product={product} />
      {product?.Attribute[0].AttrName &&
        product?.Attribute[0].AttrValue !== "value" && (
          <ProductVariants
            product={product}
            storeId={storeId}
            harvestId={harvestId}
            categoryId={categoryId}
          />
        )}
      {product.LabelText && (
        <PromoLabel color={product.LabelColorCode}>
          {product.LabelText}
        </PromoLabel>
      )}
      {price && (
        <div className="price">
          {product.Price !== 0 ? (
            <div>
              {currency}
              {product.Price.toFixed(2)}{" "}
              <span className="oldPrice">
                {currency}
                {product.RegularPrice.toFixed(2)}
              </span>
            </div>
          ) : (
            <div>
              {currency}
              {product.RegularPrice.toFixed(2)}
            </div>
          )}
          <span className="pricePerUnit">{pricePerUnit}</span>
        </div>
      )}
      <div className="priceinfo-container">
        <span>Prices may vary in club and online.</span>
        <span>
          <IoIosInformationCircleOutline />
        </span>
      </div>
      {shipmentMethods.length ? (
        <div className="shipping-methods">
          <div className="container">
            {shipmentMethods.map((method, key) => (
              <ShippingMethod
                key={key}
                method={method}
                isActive={shippingMethodSelected === method.name.toLowerCase()}
                onClick={() =>
                  setShippingMethodSelected(method.name.toLowerCase())
                }
              />
            ))}
          </div>
          <div className="description">
            {shipmentMethods.map((method, key) => (
              <div key={key}>
                {shippingMethodSelected === method.name.toLowerCase()
                  ? method.description
                  : ""}
              </div>
            ))}
            {shippingMethodSelected === "shipping" && (
              <span>
                Free shipping for <span className="plus">Plus</span>
              </span>
            )}
          </div>
        </div>
      ) : null}
      <AddToCartButton product={product} url={url} />
      <PlusMemberContainer>
        <div className="plus-member-container">
          <IoAddCircleOutline />
          <span className="plus-member">Become a Plus member</span>
        </div>
        <span className="plus-description">Free shipping on most items</span>
      </PlusMemberContainer>
    </PdpCardStyled>
  );
};

import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useParams, useNavigate } from "react-router-dom";
import { useConfiguration } from "../../hooks/useConfiguration";
import { IProduct } from "../../types";
import { ProductCard } from "../ProductCard";
import { useAppContext } from "../../context";
import {
  normalizeNameForUrl,
  contentSquereCustomTag,
  artificialPageview,
} from "../../helpers";
import { actions } from "../../context/actions";
import { Pagination } from "@mui/material";
import { theme } from "../../theme";
import { ModalTypes } from "../Modals/types";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { TCurrency } from '../../types/config';
import { AdvBanner } from '../AdvBanner';
export const ProductsContainer = ({
  products,
  name,
  productsBeforeFiltering,
  promoBannerData,
}: {
  products: IProduct[];
  name: string;
  productsBeforeFiltering: IProduct[];
  promoBannerData?: {
    TermBannerImage: { fullpath: string };
    TermBannerProducts: IProduct[];
    TermBannerSponsoredBy: string;
    TermBannerText: string;
    AddProductButton?: string;
    Currency: TCurrency;
  };
}) => {
  const {
    storeId = "",
    categoryId,
    termId,
    pageNr,
    harvestId = "",
  } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { Columns } =useConfiguration({
    storeId,
    harvestId,
  });
  const currentPage = Number(pageNr) || 1;
  const [productLength, setProductLength] = useState(0);
  let itemsPerPage = 16;

  const handlePaginationChange = (e: any, value: any) => {
    navigate(
      categoryId
        ? `/${storeId}/${harvestId}/category/${categoryId}/${value}`
        : `/${storeId}/${harvestId}/search/${termId}/${value}`
    );
  };
  const currentProductList = products.filter(
    (item: IProduct, index: number) => {
      if (
        index >= itemsPerPage * (currentPage - 1) &&
        index < itemsPerPage * currentPage
      )
        return item;
    }
  );
  const firstItemRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const newLength = products.filter((item) => item).length;
    if (productLength !== newLength) {
      setProductLength(newLength);
      dispatch({ type: actions.SET_PRODUCT_LENGTH, payload: newLength });
    }
  }, [products]);

  useEffect(() => {
    if (window.location.pathname.includes("category")) {
      contentSquereCustomTag(
        1,
        "Path",
        window.location.pathname + window.location.hash.replace("#", "?__"),
        ""
      );
      contentSquereCustomTag(2, "pageType", "Category", "");
      artificialPageview(
        window.location.pathname + window.location.hash.replace("#", "?__")
      );
    }
    if (window.location.pathname.includes("search")) {
      contentSquereCustomTag(
        1,
        "Path",
        window.location.pathname + window.location.hash.replace("#", "?__"),
        ""
      );
      contentSquereCustomTag(2, "pageType", "SERP", "");
      artificialPageview(
        window.location.pathname + window.location.hash.replace("#", "?__")
      );
    }
  }, [name]);

  const handleOpenFilterModal = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.filtersMenu,
        modalContent: { productsBeforeFiltering },
      },
    });
  };
  return (
    <ProductsContainerStyled>
      <FilterContainer>
        <p>{productLength} Results</p>
        <ShowFilterButtonStyled onClick={handleOpenFilterModal}>
          Sort & filter
        </ShowFilterButtonStyled>
      </FilterContainer>

      <ProductListStyled>
        {products.length > 0 &&
          currentProductList.map((product: IProduct, index: number) => {
            const isPromoBanner = product?.Video?.title === '__promoBannerPlaceholder__';
            let itemIndex = index;
            const { id, Name, Price, RegularPrice, Brand, URLPromoCode } =
              product;
            const price = Price ? Price : RegularPrice;
            const brand = Brand ? Brand : "";
            if (isPromoBanner && !products[index - 1]?.fullpath?.includes("Products")) // prev video
                itemIndex = index - 1;
            if (isPromoBanner && !products[index + 1]?.fullpath?.includes("Products")) // next video
              itemIndex = index + 1;
            const position = itemsPerPage * (currentPage - 1) + itemIndex + 1;
            let promoCode;
            if (URLPromoCode != null) {
              promoCode = "__promoCode-" + URLPromoCode;
            } else {
              promoCode = "__promoCode-";
            }
            let newColumns = 4;
            let cssClass = '';
            if (Columns !== undefined) { newColumns = Columns; }
            if (position === 1 || position % newColumns === 1) { cssClass = 'fullsize'; }
            if (isPromoBanner) {
              return promoBannerData?.TermBannerProducts && <AdvBanner {...promoBannerData} />
            } else if (!product?.fullpath?.includes("Products")) {
              return (
                <div className={'video '+cssClass}>
                  <video src={`${imgSrcByEnv()}${product?.Video?.data.fullpath}`} controls autoPlay muted loop/>
                </div>
              )
            } else {
            return (
              product && (
                <div key={id} ref={index === 0 ? firstItemRef : null}>
                  <ProductCard
                    product={product}
                    url={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}_${price}_${position}${promoCode}`
                    }
                    onClick={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${id}__${brand}__${normalizeNameForUrl(
                            Name
                          )}_${price}_${position}${promoCode}`
                    }
                  />
                </div>
              )
            );
            }
          })}
      </ProductListStyled>
      {productLength && (
        <PaginationStyled
          shape="rounded"
          count={Math.ceil(productLength / itemsPerPage)}
          page={currentPage}
          onChange={handlePaginationChange}
          hidePrevButton={currentPage === 1}
          hideNextButton={
            currentPage === Math.ceil(productLength / itemsPerPage)
          }
        />
      )}
    </ProductsContainerStyled>
  );
};
const ProductsContainerStyled = styled.div`
  width: 100vw;
  .video{
    margin-bottom: -90px;
    margin-left:auto;
    margin-right:auto;
    video {
      width: 100%;
      padding: 10px 0 0 0;
    }
  }
  
  .fullsize {
    width: 100%;

    grid-column: 1 / span 4;

     @media (max-width: 1140px) {
      grid-column: auto;
    }
    @media (max-width: 768px) {
      grid-column: 1 / span 2;
    }
    @media (max-width: 550px) {
      grid-column: 1 / span 1;
    }
  }
    
  video {
    width: 100%;
    padding:26px 0 0 0;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  align-items: end;
  font-size: 15px;
  font-weight: 600;
`;

const ShowFilterButtonStyled = styled.a`
  margin-top: 5px;
  color: ${theme.samsclub.steelblue};
  cursor: pointer;
`;

const ProductListStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
`;

const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

import React from "react";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_CATEGORY_BY_TERM } from "../../../queries";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FilterModalCommonStyles } from "../commonStyles";
import { FilterModalButtons } from "../../FilterModalButtons";
import {
  CategoriesStyled,
  HeaderStyled,
  ContentStyled,
  ButtonsContainer,
  IconButtonStyled,
} from "./styles";
import { useCategoriesFilter } from "./useCategoriesFilter";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { GET_LOCALIZED } from "../../../queries";
import { CustomCheckbox } from "../../SucessCheckbox";
import { IProduct } from "../../../types";

export const CategoriesFilterModal = ({
  productsBeforeFiltering,
  buttonsOff,
  header,
  handleClose,
}: any) => {
  const { searchBar, handleSubmit, handleClear, handleCheckboxes, checked } =
    useCategoriesFilter({ productsBeforeFiltering });

  const { storeId = "", termId, harvestId = "" } = useParams();

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split("__")[0],
    })
  );

  const edges = data?.getEPogTermListing?.edges;

  const categories = edges
    .map((edge: any) => edge?.node?.CategoriesFilterElements)
    .flat();

  if (categories.length > 1) {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        {header && (
          <HeaderStyled>
            <span>Categories</span>
            <IconButtonStyled onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButtonStyled>
          </HeaderStyled>
        )}
        <CategoriesStyled>
          <ContentStyled>
            <div className="checkboxes">
              <FormGroup>
                {categories &&
                  Object.values(categories).map((value: any, index: number) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={categories[index].CategoryName}
                            checked={checked[categories[index].CategoryName]}
                            onChange={() =>
                              handleCheckboxes(categories[index].CategoryName)
                            }
                            color="success"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 70,
                                borderRadius: 20,
                              },
                            }}
                          />
                        }
                        label={categories[index].CategoryName}
                        labelPlacement="start"
                      />
                    );
                  })}
              </FormGroup>
            </div>
          </ContentStyled>
          <ButtonsContainer>
            <FilterModalButtons onSubmit={handleSubmit} onClear={handleClear} />
          </ButtonsContainer>
        </CategoriesStyled>
      </FilterModalCommonStyles>
    );
  } else {
    return (
      <FilterModalCommonStyles buttonsOff={buttonsOff}>
        {header && (
          <HeaderStyled>
            <span>Categories</span>
            <IconButtonStyled onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </IconButtonStyled>
          </HeaderStyled>
        )}
        <CategoriesStyled>
          <ContentStyled>
            <div className="checkboxes">
              <FormGroup>
                {productsBeforeFiltering ? (
                  productsBeforeFiltering
                    .filter(
                      (product: IProduct, index: number, arr: IProduct[]) =>
                        product.SubCategory?.toLowerCase().includes(
                          searchBar.toLowerCase()
                        ) &&
                        index ===
                          arr.findIndex(
                            (item) => item.SubCategory === product.SubCategory
                          )
                    )
                    .map((product: IProduct, index: number) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <CustomCheckbox
                            name={product.SubCategory}
                            checked={checked[product.SubCategory]}
                            onChange={() =>
                              handleCheckboxes(product.SubCategory)
                            }
                          />
                        }
                        label={product.SubCategory}
                        labelPlacement="start"
                      />
                    ))
                ) : (
                  <div></div>
                )}
              </FormGroup>
            </div>
          </ContentStyled>
        </CategoriesStyled>
      </FilterModalCommonStyles>
    );
  }
};

import styled from "@emotion/styled";
import Button from "@mui/material/Button";

/*export const Container = styled.div`
  display: flex;
  justify-content: center;

  gap: 24px;
  border-radius: 10px;
  padding: 40px;
  margin: 24px auto 64px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 650px) {
    padding: 15px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
  }

  .products-container {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: 650px) {
      justify-content: center;
    }
  }
`;

export const BrandContainer = styled.div`
  display: inline-block;
  margin: 0;
  display: flex;
  gap: 15px;
  & > div {
    width: 50%;
  }
  @media (max-width: 768px) {
    display: block;
  }
  img {
    height: 161px;
    width: 100%;
    object-fit: contain;
  }
`;

export const ProductContainer = styled.span`
  display: inline-block;
  max-width: 320px;
  display: flex;

  margin: 0 !important;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 10px;
  }

  flex-direction: column;
  gap: 10px;
  @media (max-width: 768px) {
    text-align: left;
  }

  & > div > img {
    height: 120px;
    width: 120px;
    object-fit: contain;
    margin-bottom: 21px;
    &:hover {
      cursor: pointer;
    }
  }

  & > div:first-of-type {
    max-width: 140px;
  }
`;

export const Header = styled.h1`
  max-width: 231px;
  height: 72px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
`;
*/

export const Container = styled.div`
  display: flex;
  justify-content: center;

  gap: 24px;
  border-radius: 10px;
  padding: 40px 5px 40px 5px;
  margin: 24px auto 64px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 650px) {
    padding: 15px;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
  }

  .brand-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .products-container {
    width: 60%;
    display: flex;
    gap: 15px;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: 650px) {
      justify-content: center;
    }
  }
`;

export const BrandContainer = styled.div`
  display: inline-block;
  margin: 0;
  display: flex;
  gap: 15px;
  width: 40%;
  & > div {
    width: 50%;
  }
  @media (max-width: 768px) {
    display: block;
  }
  img {
    height: 120px;
    object-fit: contain;
  }
`;

export const Header = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
`;

export const ProductContainer = styled.span`
  display: inline-block;
  max-width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 !important;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 10px;
  }

  gap: 10px;
  @media (max-width: 768px) {
    text-align: left;
  }

  & > div > img {
    height: 120px;
    width: 120px;
    object-fit: contain;
    margin-bottom: 21px;
    &:hover {
      cursor: pointer;
    }
  }

  & > div:first-of-type {
    max-width: 140px;
  }
`;

export const Paragraph = styled.p`
  height: 17px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 34px;
`;

export const ButtonCustom = styled(Button)`
  max-width: 120px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props: any) => props.theme.productAddToCartBg};
  background: transparent;
  border-radius: 3px;
  border: 1px solid ${(props: any) => props.theme.productAddToCartBg};
  padding: 3px 11px;
`;

export const ProductHeader = styled.h1`
  width: 144px;
  height: 68px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
`;

export const DescriptionContainer = styled.div`
  & > div {
    margin-top: 10px;
  }
  & > p:first-of-type {
    margin-top: 10px;
  }
  & > p:nth-of-type(2) {
    margin: 12px 0 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
`;

export const BannerVideo = styled.video<{isVideoOnTop:boolean}>`
display:block;
width:100%;
margin: 0 auto;
margin-top: ${({ isVideoOnTop }) => isVideoOnTop ? "90px" : "15px"};
margin-bottom: ${({ isVideoOnTop }) => isVideoOnTop ? "-90px" : "0"};
`
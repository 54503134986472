import styled from '@emotion/styled';
import { theme } from '../../theme';

export const PdpCardStyled = styled.div`
  background: ${theme.samsclub.white};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0 5px 24px;
  width: 100%;

  & > p:nth-of-type(1) {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${theme.samsclub.black};
    margin-bottom: 16px;
    margin-top: 18px;
  }

  .price {
    font-weight: 600;
    font-size: 28px;
    line-height: 22px;
    color: ${theme.samsclub.black};
    display: flex;
    align-items: center;
    margin: 10px 0 10px;
    span {
      color: ${theme.samsclub.greyDark};
      margin-top: 5px;
      margin-left: 5px;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .priceinfo-container {
    display: flex;
    margin-bottom: 20px;
    span{
      font-size: 14px;
      margin-right: 5px;
    }
    svg{
      font-size: 14px;
      font-weight: 600;
      color: ${theme.samsclub.primary};
    }
  }

  .addToCartPDPButton {
    margin: 15px 0 15px 0;
    float: right;
  }

  .oldPrice {
    font-size: 15px;
    color: ${theme.samsclub.greyDark};
    text-decoration: line-through
  }

  .attributes {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: ${theme.samsclub.black};
    margin: 10px 0;
  }

  .shipping-methods {
    .container {
      display: flex;
      gap: 10px;
    }
    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: ${theme.samsclub.black};
      margin-bottom: 24px;
      text-decoration-line: none;
      margin-bottom: 16px;
    }
    button{
      svg{
        font-size: 20px;
      }
    }
    .description {
      div{
        margin: 20px 0px 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 15px;
      }
      & > span {
        font-size: 12px;
        font-weight: 500;
      }
      .plus {
        color: ${theme.samsclub.primary};
      }
    }
  }

  .brand-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:nth-of-type(1) {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: ${theme.samsclub.black};
      display: inline-block;
    }

    & > div {
      display: flex;
      align-items: center;
    }
  }

  & > p:nth-of-type(2) {
    margin-bottom: 10px;
  }

  & > p:nth-of-type(4) {
    margin: 12px 0;
  }

  & > hr {
    margin: 12px 0 !important;
  }

  & > p > span {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: ${theme.samsclub.black};
  }

  .rating {
    display: flex;
    align-items: center;
    & > span {
      margin-left: 5px;
    }
    span {
      color: ${theme.samsclub.black};
      font-size: 16px;
    }
  }
  .cart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    .cart-manipulation {
      width: 30%;
      & > span {
        margin: 0 10px;
        @media (max-width: 991px) {
          margin: 0 3px;
        }
      }
    }

    & > button {
      width: 90%;
      border-radius: 20px;
      text-transform: capitalize;
    }
  }

  .variants-container {
    & > p {
      margin: 10px 0;
    }
    & > .variants-inner-container {
      & > p {
        font-weight: 500;
        font-size: 12px;
        margin: 10px 0;
      }
  }
  .variants {
    display: flex;
    gap: 16px;
    max-width: 312px;
    overflow-x: auto;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    .active {
      border: 2px solid ${theme.samsclub.primary};
    }

    & > div {
      border: 1px solid ${theme.samsclub.grey};
      border-radius: 3px;
      max-width: 105px;
      min-width: 100px;
      min-height: 70px;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 85px;
        width: 85px;
        object-fit: contain;
        display: block;
      }
      p {
        margin-top: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: ${theme.samsclub.black};
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
      }
      .variant-price {
        font-weight: 700;
      }
    }
  }
}
`;
export const PlusMemberContainer = styled.div`
  margin-top: 16px;
  padding: 12px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #F2F8FC;
  border-radius: 8px;

  .plus-member-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    svg{
      font-size: 16px;
      color: ${theme.samsclub.primary};
    }
  }

  .plus-member {
    font-size: 16px;
    font-weight: 700;
    color: ${theme.samsclub.primary};
    margin-left: 8px;
  }

  .plus-description {
    display: block;
    font-size: 14px;
    color: ${theme.samsclub.grey};
    margin-top: 4px;
    margin-bottom: 10px;
  }
`;
export const AddButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
`;
export const SponsoredContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`
export const PromoLabel = styled.div<{ color?: string }>`
  display: inline-block;
  color: ${theme.samsclub.white};
  background-color: ${({ color }) => color};
  font-size: 14px;
  padding: 8px; 
  border-radius: 5px;
  font-weight: 600;
  white-space: nowrap; 
`;

import { HomeCategoriesSlider } from "./HomeCategoriesSlider";
import { HomeInstantSavingsBanner } from "./HomeInstantSavingsBanner";
import { HomeShopDepartmentsBanner } from "./HomeShopDepartmentsBanner";
import { HomeMemberPerks } from "./HomeMemberPerks";
import { HomeSavingsOfferBanner } from "./HomeSavingsOfferBanner";
import { HomeFooterSection } from "./HomeFooterSection";
import { HomeOffersBanner } from "./HomeOffersBanner";
import { HomeProductsSection } from "./HomeProductsSliderSection";
import { ImageCarousel } from "../../components/Carousel/ImageCarousel";
import image_2 from "../../assets/images/image_2.webp";
import image_3 from "../../assets/images/image_3.webp";
import image_6 from "../../assets/images/image_6.webp";
import styled from "@emotion/styled";
import { theme } from "../../theme";
import { useMainRoute } from "./useMainRoute";
import { ICategory, IProduct } from "../../types";
interface IShuffledProducts {
  shuffledSimilar: IProduct[];
  shuffledRecommended: IProduct[];
}

export const MainRoute = () => {
  const {
    shuffledProducts,
    categories,
  }: {
    shuffledProducts: IShuffledProducts;
    categories?: { node: ICategory }[];
  } = useMainRoute();
  const imagesArray = [image_2, image_3, image_6];

  return (
    <StyledComponentWrapper>
      <HomeInstantSavingsBanner />
      <HomeShopDepartmentsBanner />
      <ImageCarousel CarouselItems={imagesArray} />
      <HomeMemberPerks />
      <HomeSavingsOfferBanner />
      <HomeProductsSection
        title={"Trending Items"}
        shuffledProducts={shuffledProducts}
      />
      <HomeProductsSection
        title={"Best Sellers"}
        shuffledProducts={shuffledProducts}
      />
      <HomeOffersBanner />
      <HomeCategoriesSlider categories={categories} />
      <HomeFooterSection />
    </StyledComponentWrapper>
  );
};

const StyledComponentWrapper = styled.div`
  min-height: 100%;
  max-width: 100vw;
  background-color: ${theme.samsclub.greyLight};
  box-sizing: border-box;
  margin-top: 78px;
`;

import { IoChevronBackOutline } from "react-icons/io5";
import { theme } from "../../theme";
import { TbSearch } from "react-icons/tb";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_CATEGORY_BY_TERM } from "../../queries";

export const Header = ({
  currentPath,
  handleGoToPreviousScreen,
  storeId,
  harvestId,
}: {
  currentPath: string;
  handleGoToPreviousScreen: () => void;
  storeId: string;
  harvestId: string;
}) => {
  const cartPath = currentPath.includes("cart");
  const cartAddPath = currentPath.includes("cartadd");
  const { termId } = useParams();

  const { data: nameData, loading: nameLoading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({ termId }),
    {
      skip: !termId,
    }
  );

  const termListingName = nameData?.getEPogTermListing?.edges[0]?.node?.Name;

  const capitalizeFirstLetterOfEachWord = (str: string) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const capitalizedTermListingName = termListingName
    ? capitalizeFirstLetterOfEachWord(termListingName)
    : "";

  return (
    <HeaderContainer>
      {cartPath ? (
        <CloseButton onClick={handleGoToPreviousScreen}>Close</CloseButton>
      ) : (
        <StyledPreviousScreenButtonWrapper left>
          <StyledPreviousScreenButton onClick={handleGoToPreviousScreen} />
        </StyledPreviousScreenButtonWrapper>
      )}
      <ScreenTitle>
        {cartPath && !cartAddPath
          ? "Cart"
          : capitalizedTermListingName || "Product Details"}
      </ScreenTitle>
      <IconGroup>
        {!cartPath && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TbSearch
              size={24}
              color={theme.samsclub.white}
              style={{ marginBottom: "4px" }}
            />
          </div>
        )}
      </IconGroup>
    </HeaderContainer>
  );
};

const StyledPreviousScreenButtonWrapper = styled.div<{ left?: boolean }>`
  justify-content: center;
  user-select: none;
  width: 80px;
  padding-left: ${({ left }) => (left ? "12px" : "0px")};
  margin-right: ${({ left }) => (left ? "0px" : "8px")};
`;

const StyledPreviousScreenButton = styled(IoChevronBackOutline)`
  font-size: 24px;
  color: ${theme.samsclub.white};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0px;
  position: relative;
  height: 78px;
`;

const CloseButton = styled.p`
  font-size: 16px;
  margin-left: 12px;
  width: 60px;
  font-weight: 600;
  cursor: pointer;
  position: absolute;
  left: 12px;
  color: ${theme.samsclub.white};
`;

const ScreenTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  color: ${theme.samsclub.white};
  left: 50%;
  transform: translateX(-50%);
`;

const IconGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 14px;
  width: 80px;
  color: ${theme.samsclub.white};
`;

import { theme } from "../../theme";
import { HomeProductsSlider } from "./HomeProductsSlider";
import styled from "@emotion/styled";

export const HomeProductsSection = ({
  title,
  shuffledProducts,
}: {
  title: string;
  shuffledProducts: any;
}) => {
  const recommended = shuffledProducts.shuffledRecommended;
  const similar = shuffledProducts.shuffledSimilar;

  return (
    <StyledHomeProductsSection>
      <HomeProductsSlider
        shuffledProducts={title.includes("Trending") ? recommended : similar}
        title={title}
      />
    </StyledHomeProductsSection>
  );
};

const StyledHomeProductsSection = styled.div`
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px 0px;
  background-color: ${theme.samsclub.white};
`;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faCar, faBox } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { theme } from "../../../theme";

const getIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case "shipping":
      return <FontAwesomeIcon icon={faTruck} />;
    case "pickup":
      return <FontAwesomeIcon icon={faCar} />;
    case "delivery":
      return <FontAwesomeIcon icon={faBox} />;
    default:
      return null;
  }
};

export const ShippingMethod = ({
  method,
  isActive,
  onClick,
}: {
  method: { name: string; icon: any; description: string };
  isActive?: boolean;
  onClick: () => void;
}) => {
  return (
    <ShippingMethodStyled onClick={onClick} isActive={isActive}>
      {getIcon(method.name)} {method.name}
    </ShippingMethodStyled>
  );
};
const ShippingMethodStyled = styled.button<{
  isActive?: boolean;
}>`
  &:hover {
    cursor: pointer;
  }
  height: 100px;
  text-align: left;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex: 1;
  color: ${({ isActive }) =>
    isActive ? `${theme.samsclub.black}` : `${theme.samsclub.grey}`};
  background-color: ${({ isActive }) =>
    isActive ? "#f4fdfe" : `${theme.samsclub.greyLight}`};
  box-shadow: ${({ isActive }: any) =>
    isActive
      ? `0 0 0 0.125rem ${theme.samsclub.primary}`
      : "0 0 0 0.0625rem #bbc8d1"};
  border-radius: 5px;
  border: none;

  svg {
    color: ${({ isActive }) =>
      isActive ? `${theme.samsclub.primary}` : `${theme.samsclub.grey}`};
    margin-bottom: 5px;
  }
`;

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { actions } from "../../context/actions";
import { useAppContext } from "../../context";
import { IProduct } from "../../types";
import { contentSquarePageEvent, dynataAddToCart } from "../../helpers";
import { GradientCircularProgress } from "../CircularProgress";
import { TextAndStepper } from "./variants/TextAndStepper";
import { theme } from "../../theme";
export const AddToCartButton = ({ product, url }: any) => {
  const [newAmount, setNewAmount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const {
    dispatch,
    value: { cart },
  } = useAppContext();

  const handleAddToCartSubmit = () => {
    contentSquarePageEvent("addToCart");
    if (newAmount > 0) {
      dispatch({
        type: actions.ADD_TO_CART,
        payload: { ...product, newAmount },
      });
      dynataAddToCart(url + "_cartadd" + newAmount);
    }
    if (newAmount === 0) {
      dynataAddToCart(url + "_cartadd" + newAmount);
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: "REMOVE_ALL", product },
      });
    }
  };
  useEffect(() => {
    const productInCart = cart?.find(
      (productInCart: IProduct) => product.id === productInCart?.id
    );

    setNewAmount(productInCart?.amount || 0);
  }, [cart, product]);

  const handleAddToCartChange = (type: string) => {
    setLoading(true);
    setTimeout(() => {
      if (type === "plus") {
        contentSquarePageEvent("addToCartIncrease");
        dynataAddToCart(url + "_cartadd" + (newAmount + 1));
        dispatch({ type: actions.ADD_TO_CART, payload: { ...product } });
      }
      if (type === "minus") {
        contentSquarePageEvent("addToCartDecrease");
        if (newAmount <= 0) {
          dynataAddToCart(url + "_cartadd");
        } else {
          dynataAddToCart(url + "_cartadd" + (newAmount - 1));
        }
        dispatch({
          type: actions.REMOVE_FROM_CART,
          payload: { product, type: "REMOVE_ONE" },
        });
      }
      setLoading(false);
    }, 800);
  };

  return (
    <Wrapper>
      <AddButton>
        {!isLoading ? (
          <TextAndStepper
            newAmount={newAmount}
            AddProductButton={"Add"}
            handleAddToCartSubmit={handleAddToCartSubmit}
            handleAddToCartChange={handleAddToCartChange}
            variant={"small"}
          />
        ) : (
          <GradientCircularProgress />
        )}
      </AddButton>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export const AddButton = styled.div`
  display: inline-flex;
  align-items: center;
  background: ${theme.samsclub.productAddToCartBg};
  color: ${theme.samsclub.white};
  border-radius: 20px;
  border: none;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  height: 35px;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  & > span {
    margin: 0 8px;
  }
`;

import { IoChevronForward } from "react-icons/io5";
import { theme } from "../../theme";
import styled from "@emotion/styled";

export const HomeShopDepartmentsBanner = () => (
  <StyledShopDepartmentsBanner>
    <p>Shop all departments</p>
    <IoChevronForward />
  </StyledShopDepartmentsBanner>
);

const StyledShopDepartmentsBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 12px 12px;
  background-color: ${theme.samsclub.white};

  p {
    font-size: 16px;
    font-weight: 500;
    font-family: Montserrat;
  }

  svg {
    font-size: 20px;
    color: ${theme.samsclub.blue?.steelblue};
  }
`;

import footer_1 from "../../assets/images/footer_1.webp";
import styled from "@emotion/styled";

export const HomeFooterSection = () => {
  return (
    <StyledHomeFooterSection>
      <img src={footer_1} alt="" />
    </StyledHomeFooterSection>
  );
};

const StyledHomeFooterSection = styled.div`
  margin-bottom: 60px;

  img {
    width: 100%;
  }
`;

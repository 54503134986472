import styled from "@emotion/styled";

export const HomeOffersBanner = () => {
  return (
    <StyledImageContainer>
      <img
        src={
          "https://scene7.samsclub.com/is/image/samsclub/20240513-hppov-mayisb--m?$med$"
        }
        alt=""
      />
    </StyledImageContainer>
  );
};

const StyledImageContainer = styled.div`
  img {
    width: 100%;
  }
`;

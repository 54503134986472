import React from "react";
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const SpinnerWithBackdrop = ({ size }: { size: number }) => (
  <Backdrop>
    <CircularProgress size={size} style={{ color: "white" }} />
  </Backdrop>
);

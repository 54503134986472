
import styled from '@emotion/styled';
import { ModalTypes } from '../Modals/types';
import { theme } from '../../theme';

export const FiltersMenuStyled = styled.div<{
  modalType?: ModalTypes;
  isInlineFilters?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '90vh' : 'auto'};
  width: ${({ modalType }) =>
    modalType === ModalTypes.filtersMenu ? '40vw' : 'auto'};

  @media (max-width: 767px) {
    width: auto;
    padding-bottom: 100px; 
  }

  max-width: 420px;
  min-height: ${({ isInlineFilters }) => (isInlineFilters ? 'auto' : '100vh')};

  .filters {
    flex: 1;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    height: 100vh;
    .filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${theme.samsclub.grey};
      padding: 20px 10px;
      font-family: 'San francisco';
      span {
        letter-spacing: 0em;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: ${theme.samsclub.black};
        cursor: pointer;
      }
      svg {
        font-size: 18px;
        cursor: pointer;
      }
    }
  }

  .filter-header {
    position: sticky;
    background-color: ${theme.samsclub.white};
    border-bottom: 1px solid ${theme.samsclub.grey};
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px 16px 16px 5px;
    & > span {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0em;
    }
    .reset{
      color: ${theme.samsclub.grey};
      font-weight: 500;
    }
    & > svg {
      color: ${theme.samsclub.greyDark};
      font-size: 30px;
      cursor: pointer;
    }
    .active-filter-header{
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      position: relative;
      padding: 6px;
        & > span {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0em;
      }
      svg{
        position: absolute;
        left: 0;
        font-size: 28px;
      }
    }
  }
    .back-icon {
      color: ${theme.samsclub.grey};
      font-size: 24px;
    }
    & > svg:first-of-type {
      margin-right: 10px;
    }
    & > svg:last-of-type {
      margin-left: auto;
    }

    .filter-footer {
    position: fixed;
    z-index: 2;
    background-color: ${theme.samsclub.white};
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    box-shadow: 1px -15px 15px -22px ${theme.samsclub.grey};
    padding: 16px 16px 3em; 
    & > button {
      text-transform: capitalize;
      font-size: 18px;
      background-color: ${theme.samsclub.primary};
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      padding-top: 9px;
      padding-bottom: 9px;
    }
  }
`;
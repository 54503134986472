import styled from "@emotion/styled";
import { theme } from "../../theme";

export const HomeInstantSavingsBanner = () => (
  <StyledHomeInstantSavingsBanner>
    <p>
      Over $9,200 in Instant Savings * are here! | Ends June 2. |{" "}
      <span>Shop now</span>
    </p>
  </StyledHomeInstantSavingsBanner>
);

const StyledHomeInstantSavingsBanner = styled.div`
  box-sizing: border-box;
  padding: 12px 12px;
  background-color: ${theme.samsclub.blue?.light};

  p {
    font-size: 12px;
    margin-right: 90px;
    font-family: Montserrat;

    span {
      color: ${theme.samsclub.blue?.medium};
    }
  }
`;

import { theme } from "../../theme/index";
import { RiShoppingCart2Line } from "react-icons/ri";
import { HiMiniArrowDownTray } from "react-icons/hi2";
import { MdTrolley } from "react-icons/md";
import { GoPerson } from "react-icons/go";
import styled from "@emotion/styled";
interface IBottomNavigationButton {
  iconName: string;
  label: string;
  active: boolean;
  icon: JSX.Element;
  onClick: any;
  getTotalQuantity: any;
}

export const BottomNavigationButton = ({
  iconName,
  label,
  active,
  onClick,
  icon,
  getTotalQuantity,
}: IBottomNavigationButton) => {
  const renderIcon = (iconName: string) => {
    const iconMap: { [key: string]: JSX.Element } = {
      home: icon,
      scan: <RiShoppingCart2Line fontSize={24} />,
      reorder: <HiMiniArrowDownTray fontSize={24} />,
      account: <GoPerson fontSize={24} />,
      cart: <MdTrolley fontSize={28} />,
    };

    return iconMap[iconName] || null;
  };

  const handleClick = () => {
    onClick();
  };
  const currentPath = window.location.pathname;
  const slashCount = currentPath.split("/").length - 1;

  return (
    <IconButton onClick={handleClick}>
      {iconName === "cart" && (
        <CartBadge iconSize={24} slashCount={slashCount}>
          {getTotalQuantity()}
        </CartBadge>
      )}
      <IconWrapper active={active}>
        {renderIcon(iconName)}
        <IconLabel>{label}</IconLabel>
      </IconWrapper>
    </IconButton>
  );
};

const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  width: 100px;

  -webkit-tap-highlight-color: transparent;
`;

const IconWrapper = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  color: ${({ active }) =>
    active ? `${theme.samsclub.primary}` : `${theme.samsclub.black}`};
`;

const IconLabel = styled.span`
  font-size: 10px;
  padding-top: 4px;
  font-weight: 600;
  margin-top: 1px;
  color: ${theme.amazon.black};
`;

const CartIconContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledCartIcon = styled(MdTrolley)<{ iconSize: number }>`
  font-size: ${({ iconSize }) => `${iconSize}px`};
  margin-right: 12px;
  color: ${theme.samsclub.white};
`;

const CartBadge = styled.div<{ iconSize: number; slashCount: number }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  top: -6px;
  right: 32px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
  color: ${theme.samsclub.primary};
  background-color: white;
  border: 1px solid ${theme.samsclub.primary};
`;

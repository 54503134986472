import React, { useCallback } from "react";

import styled from "@emotion/styled";

import { useParams } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import { ModalTypes } from "./types";
import { useAppContext } from "../../context";
import { FiltersMenu } from "../FiltersMenu";
import { actions } from "../../context/actions";
import { useConfiguration } from "../../hooks/useConfiguration";
import { BrandsFilterModal } from "../Filters/BrandsFilter";
import { CategoriesFilterModal } from "../Filters/CategoriesFilter";
import { VariantsFilter } from "../Filters/VariantsFilter";
import { SpecialOffersFilterModal } from "../Filters/SpecialOffersFilter";
import { PriceRangeFilter } from "../Filters/PriceRangeFilter";
import { Sorting } from "../Filters/Sorting";
import { RatingFilter } from "../Filters/RatingFilter";
import { TransitionProps } from "@mui/material/transitions";
import { PdpDescription } from "../../routes/PdpRoute/PdpDescription";
import { Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalComponent = () => {
  const {
    value: { isModal, modalType, modalContent },
    dispatch,
  } = useAppContext();
  const { storeId = "", harvestId = "" } = useParams();
  const { FilterPosition } = useConfiguration({ storeId, harvestId });

  const handleClose = useCallback(() => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: undefined },
    });
  }, [dispatch]);

  const modalSelector = () => {
    switch (modalType as ModalTypes) {
      case ModalTypes.filtersMenu:
        return (
          <FiltersMenu
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
            handleClose={handleClose}
          />
        );

      case ModalTypes.priceRange:
        return (
          <PriceRangeFilter
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.brands:
        return (
          <BrandsFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.sorting:
        return <Sorting />;

      case ModalTypes.rating:
        return <RatingFilter />;

      case ModalTypes.variants:
        return <VariantsFilter />;

      case ModalTypes.specialOffers:
        return (
          <SpecialOffersFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );

      case ModalTypes.categories:
        return (
          <CategoriesFilterModal
            productsBeforeFiltering={modalContent.productsBeforeFiltering}
          />
        );
      case ModalTypes.description:
        return <PdpDescription />;

      default:
        return false;
    }
  };

  return (
    <DialogStyled
      open={isModal}
      onClose={handleClose}
      modalType={modalType}
      FilterPosition={FilterPosition}
      TransitionComponent={Transition}
    >
      {modalSelector()}
    </DialogStyled>
  );
};

const DialogStyled = styled(Dialog, {
  shouldForwardProp(propName) {
    return propName !== "modalType" && propName !== "FilterPosition";
  },
})<{
  modalType: ModalTypes;
  FilterPosition?: string;
}>`
  .MuiDialog-container {
    display: flex;
    justify-content: ${({ modalType, FilterPosition }) => {
      if (modalType === ModalTypes.filtersMenu) {
        if (FilterPosition === "Left") {
          return "flex-start";
        } else {
          return "flex-end";
        }
      }

      return "center";
    }};
    align-items: flex-start;
    margin-top: 132px;

    @media (max-width: 767px) {
      margin-top: 80px;
    }
  }

  .MuiDialog-paper {
    max-height: ${({ modalType }) =>
      modalType === ModalTypes.filtersMenu ? "80vh" : "85vh"};
    margin: ${({ modalType }) => modalType === ModalTypes.filtersMenu && "0"};
    width: 100%;
    max-width: 600px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media (max-width: 767px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      max-height: 100vh;
      margin-bottom: 56px;
      padding-bottom: 20px; 
    }
  }
`;

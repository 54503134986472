import React from 'react';
import { AddButton } from '..';
import { Stepper } from './Stepper';
export const TextAndStepper = ({
  AddPosition,
  handleAddToCartChange,
  newAmount,
  AddProductButton,
}: any) => {
  const preventDefault = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (newAmount === 0) handleAddToCartChange('plus');
    e.preventDefault();
  };
  return (
    <AddButton onClick={preventDefault}>
      {newAmount > 0 ? (
        <Stepper
          handleAddToCartChange={handleAddToCartChange}
          newAmount={newAmount}
        />
      ) : (
        <span>{AddProductButton}</span>
      )}
    </AddButton>
  );
};

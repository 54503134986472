import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { useConfiguration } from "../../hooks/useConfiguration";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED } from "../../queries";

interface Icategory {
  key: string;
  id: string;
  fullpath: string;
  TermImage: {
    fullpath: string;
  };
}

export const PopularSections = ({
  CarouselWidth,
  categories,
}: {
  CarouselWidth?: string;
  categories?: { node: Icategory }[];
}) => {
  const navigate = useNavigate();
  const { storeId = "", harvestId = "" } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  let placeholders = 0;
  if (categories && categories.length) {
    placeholders =
      categories.length % 3 === 0 ? 0 : 3 - (categories.length % 3);
  }

  return (
    <div
      style={{
        padding: "12px 12px 0px 12px",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          fontSize: "20px",
          fontWeight: 550,
          marginBottom: "16px",
        }}
      >
        Trending categories
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "2%",
          backgroundColor: "white",
          justifyContent: "space-between",
        }}
      >
        {categories?.map(({ node: { key, id, TermImage } }, index) => (
          <div
            key={index}
            onClick={() =>
              navigate(
                `/${storeId}/${harvestId}/category/${id}__${key.replaceAll(
                  " ",
                  "_"
                )}`
              )
            }
            style={{
              width: "30%",
              marginBottom: "16px",
              cursor: "pointer",
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={
                TermImage?.fullpath
                  ? `${imgSrcByEnv()}${TermImage?.fullpath}`
                  : "/files/generic/img/square.svg"
              }
              alt="square"
              style={{
                width: "100%",
                height: "auto",
                marginBottom: "8px",
                border: "1px solid lightgrey",
                borderRadius: "8px",
                padding: "8px",
              }}
            />
            <p style={{ width: "50px" }}>{key}</p>
          </div>
        ))}

        {Array.from({ length: placeholders }).map((_, index) => (
          <div
            key={`placeholder-${index}`}
            style={{
              width: "30%",
              visibility: "hidden",
            }}
          />
        ))}
      </div>
    </div>
  );
};

import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';
export const GradientCircularProgress = () => {
  return (
    <Loader>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#ffffff" />
            <stop offset="100%" stopColor="#ffffff9f" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{
          'svg circle': { stroke: 'url(#my_gradient)' },
        }}
        thickness={8}
        size={23}
      />
    </Loader>
  );
};

const Loader = styled.div<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 34px;
`;

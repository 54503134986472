import styled from "@emotion/styled";
import { theme } from "../../theme";

export const HomeSavingsOfferBanner = () => (
  <StyledHomeSavingsOfferBanner>
    <img
      src="https://scene7.samsclub.com/is/content/samsclub/icon-ticket"
      alt=""
    />
    <div>
      <p>Savings waiting just for you!</p>
      <p>See all offers</p>
    </div>
  </StyledHomeSavingsOfferBanner>
);

const StyledHomeSavingsOfferBanner = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background-color: ${theme.samsclub.white};
  align-items: center;
  margin-bottom: 10px;
  padding: 30px 20px;

  img {
    width: 50px;
  }

  div {
    margin-left: 20px;
    line-height: 2;

    p:first-of-type {
      font-size: 18px;
      font-weight: 600;
    }

    p:last-of-type {
      font-size: 14px;
    }
  }
`;

import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FilterModalCommonStyles } from "../commonStyles";
import styled from "@emotion/styled";
import { actions } from "../../../context/actions";
import { useAppContext } from "../../../context";
import { useParams } from "react-router-dom";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { GET_LOCALIZED } from "../../../queries";
import { useQuery } from "@apollo/client";
import { theme } from "../../../theme";
import { ModalTypes } from "../../Modals/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";

export const VariantsFilter = ({ buttonsOff, header, handleClose }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === "Variants"
  );

  const { storeId = "", termId, harvestId = "" } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const [value, setValue] = React.useState(variantsFilter?.value || "");

  const handleVariantChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: "Variants", value: newValue },
    });
  };

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      {header && (
        <HeaderStyled>
          <span>Variants</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <ComponentStyled>
        <FormControl>
          <RadioGroup onChange={handleVariantChange} value={value}>
            <StyledFormControlLabel
              value="yes"
              control={<StyledRadio />}
              label={translation?.getLocalization.ShowProductWithVariants}
              labelPlacement="start"
            />
            <StyledFormControlLabel
              value="no"
              control={<StyledRadio />}
              label={translation?.getLocalization.ShowProductWithNoVariants 
                ? translation?.getLocalization.ShowProductWithNoVariants 
                : "Show products with no variants"}
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 65vh;

  .MuiFormControl-root {
    height: 100vh;
  }
  .MuiFormControlLabel-label {
    font-size: 20px;
  }
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.samsclub.grey};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid ${theme.samsclub.grey};
  display: flex;
  justify-content: space-between;
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${theme.samsclub.green};
    font-size: 24px;
  }
`;

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${theme.samsclub.green};
  }
  .MuiSvgIcon-root {
    font-size: 24px;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.samsclub.greyDark};
  }
`;

export const ButtonsContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;

import React from "react";
import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { IProduct } from "../../types";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";
import { contentSquarePageEvent } from "../../helpers";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { theme } from "../../theme";
export const PdpGallery = ({ product }: { product: IProduct }) => {
  const gallery = product?.Gallery;
  const video = product?.VideoGallery;
  const [currentImage, setCurrentImage] = React.useState(gallery && gallery[0]?.image?.fullpath);

  let arr = [""];

  gallery && gallery.map(({ image: { fullpath } }) => {
    arr.push(fullpath);
  });

  video && video.map(({ Video: { data, poster }, VideoPosition }) => {
    arr.splice( Number(VideoPosition), 0, data.fullpath + '^^^' + poster.fullpath);
  });
  arr.shift();

  React.useEffect(() => {
    setCurrentImage(gallery && gallery[0]?.image?.fullpath);
  }, [gallery]);

  return (
    <GalleryStyled>
      <div className="big-image-container">
        {window.innerWidth <= 768 ? (
          <>
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{
                clickable: true,
                el: ".big-image-pagination",
              }}
              navigation={true}
              onSlideChange={(swiper) =>
                setCurrentImage(gallery[swiper.activeIndex]?.image?.fullpath)
              }
            >
              {arr && arr.map((elem) => {
                let videoPaths = elem.split('^^^');
                return (
                  <SwiperSlide key={elem}>
                    {!elem.includes("Videos") ?
                      <img
                      className="big-image"
                        src={`${imgSrcByEnv()}${elem
                          }`}
                        alt={"test slider image"}
                      />
                      :
                      <video
                        className="big-image"
                        src={`${imgSrcByEnv()}${videoPaths[0]
                          }`} controls autoPlay muted loop
                      />
                    }
                  </SwiperSlide>
                );
              })
              }
            </Swiper>
            <div className="big-image-pagination"></div>
          </>
        ) : (
          <>
            {currentImage.includes("Videos")?
            <video
              className="big-image"
              src={`${imgSrcByEnv()}${currentImage}`}
              controls autoPlay muted loop
            />
            :
            <img
              className="big-image"
              src={`${imgSrcByEnv()}${currentImage}`}
              alt="product packshot"
            />
            }
          </>
        )}
      </div>
      <div className="thumbnails-container">
        <Swiper
          modules={[Pagination]}
          spaceBetween={16}
          slidesPerView="auto"
          onSlideChange={(swiper) =>
            setCurrentImage(gallery[swiper.activeIndex]?.image?.fullpath)
          }
        >
        
          {arr && arr.map((elem) => {
            let videoPaths = elem.split('^^^');
            return (
              <SwiperSlide key={elem}>
                {
                  !elem.includes("Videos") ?
                    <img src={`${imgSrcByEnv()}${elem}`}
                      alt={"product slider image"}
                      className="image"
                      onClick={() => {
                        setCurrentImage(elem);
                        contentSquarePageEvent("galleryImageChange");
                      }}
                    />
                    :
                    <img src={`${imgSrcByEnv()}${videoPaths[1]}`} 
                    onClick={() => {
                      setCurrentImage(videoPaths[0]);
                      contentSquarePageEvent("galleryImageChange");
                    }}
                    className="image"/>
                }
              </SwiperSlide>
            );
          })
          }
        </Swiper>
      </div> 
    </GalleryStyled>
  );
};

const GalleryStyled = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 10px;

  .big-image-container {
    position: relative;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .big-image {
      width: 100%;
      height: 250px;
      object-fit: contain;
    }
    .big-image-pagination {
      position: absolute;
      bottom: -10px;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px 0;
      min-width: 60px;

      .swiper-pagination-bullet {
        background: lightgray;
        opacity: 0.6;
      }

      .swiper-pagination-bullet-active {
        background: ${theme.samsclub.navBg};
      }
    }
    .swiper-button-next, .swiper-button-prev{
      color: ${theme.samsclub.navBg};
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .thumbnails-container {
    max-height: 552px;
    padding-bottom: 40px;
    .swiper-slide {
      width: auto;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  .image {
    display: block;
    width: 58px;
    height: 58px;
    object-fit: contain;
    cursor: pointer;
  }
`;

import React from "react";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import { FilterModalCommonStyles } from "../commonStyles";
import { useAppContext } from "../../../context";
import { actions } from "../../../context/actions";
import { useParams } from "react-router-dom";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { GET_LOCALIZED } from "../../../queries";
import { useQuery } from "@apollo/client";
import { theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";

export const RatingFilter = ({ buttonsOff, header, handleClose }: any) => {
  const {
    value: { searchFilters },
    dispatch,
  } = useAppContext();

  const { storeId = "", harvestId = "" } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: {
      defaultLanguage: LanguageVersion,
    },
  });

  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === "Rating"
  );

  const [value, setValue] = React.useState(ratingFilter?.value || "");

  const handleRatingChange = (e: any) => {
    const newValue = e.target.value;
    setValue(newValue);
    dispatch({
      type: actions.ADD_SEARCH_FILTER,
      payload: { name: "Rating", value: newValue },
    });
  };

  return (
    <FilterModalCommonStyles buttonsOff={buttonsOff}>
      {header && (
        <HeaderStyled>
          <span>Rating</span>
          <IconButtonStyled onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButtonStyled>
        </HeaderStyled>
      )}
      <ComponentStyled>
        <FormControl>
          <RadioGroup onChange={handleRatingChange} value={value}>
            <StyledFormControlLabel
              value="4"
              control={<StyledRadio />}
              label={
                <RatingComponent
                  rating={4}
                  text={translation?.getLocalization.FourOrMore}
                />
              }
              labelPlacement="start"
            />
            <StyledFormControlLabel
              value="3"
              control={<StyledRadio />}
              label={
                <RatingComponent
                  rating={3}
                  text={translation?.getLocalization.ThreeOrMore}
                />
              }
              labelPlacement="start"
            />
            <StyledFormControlLabel
              value="2"
              control={<StyledRadio />}
              label={
                <RatingComponent
                  rating={2}
                  text={translation?.getLocalization.TwoOrMore}
                />
              }
              labelPlacement="start"
            />
            <StyledFormControlLabel
              value="1"
              control={<StyledRadio />}
              label={
                <RatingComponent
                  rating={1}
                  text={translation?.getLocalization.OneOrMore}
                />
              }
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </ComponentStyled>
    </FilterModalCommonStyles>
  );
};

const ComponentStyled = styled.div`
  position: relative;
  height: 65vh;
  display: flex;
  flex-direction: column;
  .form-control-container {
    flex: 1;
    overflow-y: auto;
  }

  .buttons {
    margin-top: auto;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  border-bottom: 1px solid ${theme.samsclub.grey};
  padding: 10px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  .MuiFormControlLabel-labelPlacementStart {
    order: -1;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${theme.samsclub.primary};
    font-size: 24px;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  & > svg {
    color: ${theme.samsclub.greyDark};
  }
`;

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${theme.samsclub.primary};
  }
  .MuiSvgIcon-root {
    font-size: 24px;
  }
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid ${theme.samsclub.grey};
`;

export const ButtonsContainer = styled.div`
  background-color: #fff;
  padding: 16px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 70px;
  z-index: 1000;
`;

const RatingComponent = ({
  rating,
  text,
}: {
  rating: number;
  text: string;
}) => {
  return (
    <RatingStyled spacing={1} className="stack">
      <Rating
        name="half-rating-read"
        defaultValue={rating}
        precision={0.5}
        readOnly
      />
      <div className="text">{text}</div>
    </RatingStyled>
  );
};

const RatingStyled = styled(Stack)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .MuiRating-iconFilled,
  .MuiRating-iconEmpty,
  .MuiRating-iconHover {
    font-size: 20px;
    color: gold;
  }

  .text {
    margin-left: 10px;
    font-size: 20px;
    margin-top: 0;
  }
`;

import { useNavigate, useParams } from "react-router-dom";
import { theme } from "../../../../theme";
import { PiTrolleyThin } from "react-icons/pi";
import styled from "@emotion/styled";

export const CartEmptySection = ({
  checkoutMessage,
}: {
  checkoutMessage?: string;
}) => {
  const { storeId = "", harvestId = "" } = useParams();
  const navigate = useNavigate();
  const baseUrl = `/${storeId}/${harvestId}`;

  return (
    <div>
      <Container>
        <PiTrolleyThin size={100} fill={theme.samsclub.primary} />
        <Message>
          {!checkoutMessage ? "Your cart is empty" : "Checkout finished."}
        </Message>
        <Button onClick={() => navigate(baseUrl)}>Let's go shopping</Button>
      </Container>
      <Divider />
    </div>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 80px 12px 0px 12px;
  gap: 16px;
  padding: 60px 0px;
`;

const Message = styled.p`
  font-weight: 500;
  font-size: 12px;
  font-family: Montserrat;
`;

const Button = styled.button`
  border: none;
  width: 100%;
  padding: 12px;
  font-weight: 500;
  font-family: Montserrat;
  color: ${theme.samsclub.white};
  background-color: ${theme.samsclub.primary};
`;

const Divider = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${theme.samsclub.greyLight};
`;

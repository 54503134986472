import { useState, useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../theme/index";
import { BottomNavigationButton } from "./BottomNavigationButton";
import { RiHomeLine } from "react-icons/ri";
import { useAppContext } from "../../context";
import styled from "@emotion/styled";

interface NavigationProps {
  navigation: (path: string) => void;
  storeId: string;
  harvestId: string;
}

export const bottomNavigationBarButtonData = [
  { iconName: "home", label: "Home" },
  { iconName: "reorder", label: "Reorder" },
  { iconName: "account", label: "Account" },
  { iconName: "cart", label: "Cart" },
];

export const BottomNavigationBar = ({
  navigation,
  storeId,
  harvestId,
}: NavigationProps) => {
  const baseUrl = `/${storeId}/${harvestId}`;
  const {
    value: { cart },
  } = useAppContext();

  const getActiveIcon = () => {
    const path = window.location.pathname;
    const pathParts = path.split("/").filter(Boolean);
    return pathParts.length === 2 ? "home" : pathParts[pathParts.length - 1];
  };

  const [clickedIcon, setClickedIcon] = useState<string | null>(getActiveIcon);

  const handleNavigation = (iconName: string) => {
    const navigateMap: { [key: string]: string } = {
      home: baseUrl,
      cart: `${baseUrl}/cart`,
    };

    return () => {
      if (clickedIcon !== iconName) {
        setClickedIcon(
          iconName === "home" || iconName === "cart" ? iconName : null
        );
        navigation(navigateMap[iconName]);
      }
    };
  };

  useEffect(() => {
    setClickedIcon(getActiveIcon());
  }, [window.location.pathname]);

  const getTotalQuantity = () => {
    return cart.reduce((total: any, item: any) => total + item.amount, 0);
  };

  return (
    <ThemeProvider theme={theme}>
      <BottomNavigationBarWrapper>
        <ItemsContainer>
          {bottomNavigationBarButtonData.map((button) => (
            <BottomNavigationButton
              key={button.label}
              iconName={button.iconName}
              label={button.label}
              active={clickedIcon === button.iconName}
              onClick={handleNavigation(button.iconName)}
              icon={<RiHomeLine fontSize={24} />}
              getTotalQuantity={getTotalQuantity}
            />
          ))}
        </ItemsContainer>
      </BottomNavigationBarWrapper>
    </ThemeProvider>
  );
};

const BottomNavigationBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
  background-color: ${theme.samsclub.white};
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CheckoutButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: ${theme.samsclub.primary};
`;

const CheckoutText = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: ${theme.samsclub.white};
`;

import React, { useEffect } from 'react';
import { IProduct } from '../../../types';
import { usePdpRoute } from '../usePdpRoute';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../../queries';
import { Code } from 'react-content-loader';
import styled from '@emotion/styled';
import { theme } from '../../../theme';
import { Slide } from '@mui/material';
import ProductDescription from '../../../components/ProductDescription';

const DescriptionContainer = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  width: 100%;
  .description {
    padding: 20px;
    border-radius: 8px;

    h3 {
      font-size: 24px;
      font-weight: 800;
      color: ${theme.samsclub.black};
      margin-bottom: 16px;
    }

    div {
      font-size: 14px;
      color: ${theme.samsclub.black};
      line-height: 1.4;
    }
  }
`;

export const PdpDescription = () => {
  const { loading, categoryLoading, data } = usePdpRoute();
  const { storeId = '', harvestId = '' } = useParams();

  const { LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data: translation, loading: translationLoading } = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  if (loading || categoryLoading || translationLoading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  } else {
    const product = data.getEPog as IProduct;

    return (
      <Slide in={true} direction="left">
        <DescriptionContainer>
          <div className="description">
            <h3>{translation?.getLocalization.ProductDetails}</h3>
            <ProductDescription content={product?.Description}/>
          </div>
        </DescriptionContainer>
      </Slide>
    );
  }
};

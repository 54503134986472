import { theme } from "../../theme";
import { HomeMemberPerksCard } from "./HomeMemberPerksCard";
import styled from "@emotion/styled";

export const HomeMemberPerks = () => (
  <StyledMemberPerksContainer>
    <p>
      <span>Plus</span> member perks
    </p>
    <div>
      <HomeMemberPerksCard
        iconSrc="https://scene7.samsclub.com/is/content/samsclub/icon-shipping"
        alt="Free Shipping Icon"
        description="Free shipping on most items"
      />
      <HomeMemberPerksCard
        iconSrc="https://scene7.samsclub.com/is/content/samsclub/icon-auto-tires"
        alt="Auto Tires Icon"
        description="2% Sam's Cash, up to $500/year"
      />
    </div>
    <p>
      Not a member? <span>Join Sam's Club</span>
    </p>
  </StyledMemberPerksContainer>
);

const StyledMemberPerksContainer = styled.div`
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 18px 12px;
  background-color: ${theme.samsclub.white};

  p:first-of-type {
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: 500;

    span {
      font-weight: 700;
      font-style: italic;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: 48%;
      padding: 14px 12px;
      border-radius: 4px;
      border: 1px solid ${theme.samsclub.grey};

      img {
        width: 30px;
      }

      p {
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }

  p:last-of-type {
    font-size: 13px;
    margin-top: 16px;

    span {
      font-weight: 600;
      color: ${theme.samsclub.blue?.steelblue};
    }
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { theme } from '../../theme';

export const ImageCarousel = ({ CarouselItems }: any) => {
  return (
    <CarouselStyled>
      <Swiper
        modules={[Pagination, Autoplay]}
        pagination={{ dynamicBullets: true }}
        autoplay={true}
        className="swiper"
      >
        {CarouselItems.length &&
          CarouselItems.map((image: string, index: number) => (
            <SwiperSlide key={index}>
              <Slide>
                <img src={image} alt={`carousel-${index}`} />
              </Slide>
            </SwiperSlide>
          ))}
      </Swiper>
    </CarouselStyled>
  );
};

const CarouselStyled = styled.div`
  width: 100%;
  .swiper {
  }
  .swiper .swiper-pagination-bullet {
    background: ${theme.target.black};
  }
`;

const Slide = styled.div`
  width: 100vw - 16;
  & > img {
    width: 100%;
    object-fit: cover;
    background-color: white;
    margin-bottom: 10px;
  }
`;

import { useState } from 'react';
import { IProduct } from '../../../../types';
import { CardDetailsSection } from './CardDetailsSection';
import { CardOptionsSection } from './CardOptionsSection';
import styled from '@emotion/styled';

export const CartProductCard = ({
  product,
  isLast,
}: {
  product: IProduct;
  isLast: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);

  return (
    <StyledComponentWrapper isLoading={isLoading}>
      {isLoading && <SpinnerWrapper />}
      <CardDetailsSection product={product} />
      <CardOptionsSection
        product={product}
        setShowModal={setShowModal}
        setSelectedProduct={setSelectedProduct}
        setIsLoading={setIsLoading}
        isLast={isLast}
      />
    </StyledComponentWrapper>
  );
};

const StyledComponentWrapper = styled.div<{ isLoading: boolean }>`
  position: relative;
  padding-top: 16px;

  ${({ isLoading }) =>
    isLoading &&
    `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7); /* Adjust the transparency as needed */
      z-index: 1; /* Ensure the overlay is above other content */
      pointer-events: none; /* Allow interaction with elements beneath the overlay */
    }
  `}
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

import React from "react";
import {
  ProductCartStyled,
  DescriptionContainer,
  LinkStyled,
  ImageStyled,
  SponsoredStyled,
  NameStyled,
  RatingStyled,
  PriceStyled,
  FreeShippingStyled,
  ButtonContainerStyled,
  ImageWrapperContainer,
  PricePerUnitStyled,
  PromoLabelStyled,
} from "./styles";
import { IProduct } from "../../types";
import { useConfiguration } from "../../hooks/useConfiguration";
import { useParams } from "react-router-dom";
import { Rating, Stack } from "@mui/material";
import { AddToCartButton } from "../AddToCartButton";
import { imgSrcByEnv } from "../../../config/imgSrcByEnv";

export const ProductCard = ({
  product,
  onClick,
  url,
}: {
  product: IProduct;
  onClick: string;
  url: string;
}) => {
  const { storeId = "", harvestId = "" } = useParams();
  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });

  const currency = Currency ? Currency : "$";

  const pricePerUnit =
    product.PricePerUnit && product.PricePerUnit != "0"
      ? product.PricePerUnit
      : null;
  return (
    <LinkStyled to={onClick}>
      <ProductCartStyled>
        <ImageWrapperContainer>
          <ImageStyled
            src={`${imgSrcByEnv()}${
              product?.Gallery && product?.Gallery[0]?.image?.fullpath
            }`}
            alt="gallery fullpath"
          />
          {product.LabelText && (
            <PromoLabelStyled>{product.LabelText}</PromoLabelStyled>
          )}
        </ImageWrapperContainer>
        <DescriptionContainer>
          {product.Sponsored && <SponsoredStyled>Sponsored</SponsoredStyled>}
          <NameStyled>{product.Name}</NameStyled>
          <RatingStyled>
            <Stack spacing={1}>
              <Rating
                name="half-rating-read"
                value={product.RROverall}
                precision={0.5}
                readOnly
              />
            </Stack>
            <p>{product?.RRAmount}</p>
          </RatingStyled>

          <PriceStyled>
            {product.Price && product.Price !== 0 ? (
              <div>
                {currency}
                {product.Price?.toFixed(2)}{" "}
                <span className="oldPrice">
                  {currency}
                  {product.RegularPrice?.toFixed(2)}
                </span>
              </div>
            ) : (
              <div>
                {currency}
                {product.RegularPrice?.toFixed(2)}
              </div>
            )}
            {pricePerUnit && (
              <PricePerUnitStyled>
                {currency}
                {pricePerUnit}
              </PricePerUnitStyled>
            )}
          </PriceStyled>
          <FreeShippingStyled>
            Free shipping for <span>Plus</span>
          </FreeShippingStyled>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              {product.PromoImage && (
                <img
                  src={`${imgSrcByEnv()}${
                    product?.PromoImage && product?.PromoImage.fullpath
                  }`}
                  alt="promo information"
                  style={{ width: "150px", marginRight: "6px" }}
                />
              )}
              <p>{product.PromoText}</p>
            </div>

            <div style={{ width: "100%" }}>
              <AddToCartButton product={product} url={url} />
            </div>
          </div>
        </DescriptionContainer>
      </ProductCartStyled>
    </LinkStyled>
  );
};

import styled from "@emotion/styled";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Fade, Typography } from "@mui/material";
import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { theme } from "../../theme";

const Container = styled.div`
  margin-top: 10px;
  position: relative;
  background: ${theme.samsclub.greyLight};
`;

const Header = styled.div<{ arrowPosition: any }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${({ arrowPosition }: any) =>
    arrowPosition === "right" ? "space-between" : "initial"};
`;

const Content = styled(Box)`
  max-width: 500px;

  & img {
    max-width: 500px;
    width: 100%;
    height: auto;
  }

  table {
    max-width: 400px;
    width: 100%;
    overflow-x: auto;
    display: block;
  }
`;

const Arrow = styled.span<{ arrowPosition: any }>`
  ${({ arrowPosition }: any) =>
    arrowPosition === "right"
      ? "position: absolute; right: 10px;"
      : "margin: 0 10px;"}
    svg{
        color: ${theme.samsclub.primary};
        font-size: 18px;
    }
`;

export const ExpantableComponent = ({
  title,
  children,
  fontSize = "24px",
  arrowPosition = "left",
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => setIsOpen(!isOpen);

  return (
    <Container>
      <Header onClick={toggleContent} arrowPosition={arrowPosition}>
        {arrowPosition === "left" && (
          <Arrow arrowPosition={arrowPosition}>
            <FontAwesomeIcon
              className="faIcon"
              icon={isOpen ? faChevronUp : faChevronDown}
            />
          </Arrow>
        )}
        <Typography variant="h3" style={{ fontSize }}>
          {title}
        </Typography>
        {arrowPosition === "right" && (
          <Arrow arrowPosition={arrowPosition}>
            <MdArrowForwardIos />
          </Arrow>
        )}
      </Header>
      <Fade in={isOpen} unmountOnExit>
        <Content>{children}</Content>
      </Fade>
    </Container>
  );
};

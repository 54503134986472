import React, { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Code } from "react-content-loader";
import { useLocation, useParams } from "react-router-dom";

import { AdvBanner } from "../../components/AdvBanner";
import { PopularFilters } from "../../components/PopularFilters";
import { ProductsContainer } from "../../components/ProductsContainer";
import { GET_PRODUCT_CATEGORY_BY_TERM } from "../../queries";
import { FiltersMenu } from "../../components/FiltersMenu";
import { useConfiguration } from "../../hooks/useConfiguration";
import { useAppContext } from "../../context";
import { handleFiltering, handleSorting } from "../../helpers";
import { IProduct } from "../../types";

import styled from "@emotion/styled";
import { actions } from "../../context/actions";

export const SearchRoute = () => {
  const {
    value: { isInlineFilters, searchFilters, productsSorting },
    dispatch,
  } = useAppContext();

  const { pathname } = useLocation();

  const { storeId = "", termId, harvestId = "" } = useParams();
  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split("__")[0],
    })
  );
  useEffect(() => {
    if (termId) {
      dispatch({ type: actions.SET_TERM_ID, payload: termId });
    }
  }, [termId, dispatch]);
  const { FilterVisibility, FilterPosition, AddProductButton, Currency } =
    useConfiguration({
      storeId,
      harvestId,
    });

  const handleProducts = (products: IProduct[]) => {
    const sortProducts = handleSorting({ products, productsSorting });
    const filterProducts = handleFiltering({
      products: sortProducts,
      searchFilters,
    });
    return filterProducts;
  };

  if (!loading && data) {
    const edges = data?.getEPogTermListing?.edges;

    const TermBannerImage = edges && edges[0]?.node?.TermBannerImage;
    const TermBannerProducts = edges && edges[0]?.node?.TermBannerProducts;
    const TermBannerSponsoredBy = edges && edges[0]?.node?.TermBannerSponsoredBy;
    const TermBannerText = edges && edges[0]?.node?.TermBannerText;
    const TermBannerVideo = edges && edges[0]?.node?.TermBannerVideo?.data?.fullpath;
    const TermBannerVideoPosition = edges && edges[0]?.node?.TermBannerVideoPosition;

    const products = edges
      .filter(
        (edge: any) =>
          edge?.node?.fullpath?.includes("SERP") &&
          edge?.node?.fullpath?.includes(storeId)
      )

      .map((edge: any) => edge?.node?.Products)
      .flat()
      .filter((item: any) => item);

    const categories = edges.map((edge: any) => edge?.node).flat();

    const brands: any = products.length && [
      ...new Set(products.map((product: IProduct) => product?.Brand)),
    ];

    const productsToRender = handleProducts(products);

    const name =
      data?.getEPogTermListing?.edges[0]?.node?.key || pathname.split("sk=")[1];
    const currency = Currency ? Currency : "$";

    return (
      <div style={{ marginTop: 90 }}>
        {TermBannerProducts && (
          <AdvBanner
            TermBannerImage={TermBannerImage}
            TermBannerProducts={TermBannerProducts}
            TermBannerSponsoredBy={TermBannerSponsoredBy}
            TermBannerText={TermBannerText}
            AddProductButton={AddProductButton}
            Currency={currency}
            TermBannerVideo={TermBannerVideo}
            TermBannerVideoPosition={TermBannerVideoPosition}
          />
        )}

        <PopularFilters
          products={productsToRender}
          productsBeforeFiltering={products}
          categories={categories}
          brands={brands}
        />

        <div className="container">
          {isInlineFilters &&
            FilterVisibility === "Stick" &&
            FilterPosition === "Left" && (
              <div className="filter-container">
                <div>
                  <FiltersMenu productsBeforeFiltering={products} />
                </div>
              </div>
            )}

          <ProductsContainer
            products={productsToRender}
            name={name}
            productsBeforeFiltering={products}
            promoBannerData={{ AddProductButton, Currency:currency, TermBannerImage, TermBannerProducts, TermBannerSponsoredBy, TermBannerText }} />

          {isInlineFilters &&
            FilterVisibility === "Stick" &&
            FilterPosition === "Right" && (
              <div className="filter-container">
                <div>
                  <FiltersMenu productsBeforeFiltering={products} />
                </div>
              </div>
            )}
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  }
};

// const SearchRouteStyled = styled.div`
//   margin-top: 100px;
//   .container {
//     display: flex;
//     margin-top: 20px;

//     @media (max-width: 767px) {
//       display: block;
//     }
//   }

//   .filter-container {
//     min-width: 420px;
//     @media (max-width: 1000px) {
//       min-width: 20%;
//     }
//     position: relative;
//     & > div {
//       position: sticky;
//     }
//   }
// `;

import { imgSrcByEnv } from '../../../../../config/imgSrcByEnv';
import { theme } from '../../../../theme';
import { useNavigate, useParams } from 'react-router-dom';
import { IProduct } from '../../../../types';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';

export const CardDetailsSection = ({ product }: { product: IProduct }) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { storeId = '', harvestId = '' } = useParams();
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const normalizeNameForUrl = (name: string | null) => {
    if (!name) return '';
    return name
      .normalize('NFD')
      .replaceAll(' ', '_')
      .replace(/[^a-zA-Z0-9_ ]/g, '');
  };

  const navigateToProduct = ({
    id,
    Brand,
    Name,
    Price,
    URLPromoCode,
    amount,
  }: IProduct) => {
    navigate(
      `/${storeId}/${harvestId}/ips/${id}__${Brand}__${normalizeNameForUrl(
        Name
      )}_${Price}${
        URLPromoCode ? '__promoCode-' + URLPromoCode : '__promoCode-'
      }_cartadd${amount}`
    );
  };

  return (
    <CartShippingCard>
      <CardImageContainer onClick={() => navigateToProduct(product)}>
        {product?.Gallery && product.Gallery.length > 0 && (
          <img
            src={`${imgSrcByEnv()}${product.Gallery[0]?.image?.fullpath}`}
            alt="product"
          />
        )}
      </CardImageContainer>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CardProductDetailsContainer>
          <CardProductDealsContainer>
            <CardProductDetailsParagraph
              color={theme.target.turquoise}
            ></CardProductDetailsParagraph>
          </CardProductDealsContainer>

          <CardProductDetailsParagraph
            onClick={() => navigateToProduct(product)}
          >
            {product.Name}
          </CardProductDetailsParagraph>

          {product?.Attribute &&
            product.Attribute.length > 0 &&
            product.Attribute.map((atr) => (
              <CardProductDetailsParagraph key={atr?.AttrName}>
                {atr.AttrName} {atr.AttrValue}
              </CardProductDetailsParagraph>
            ))}
        </CardProductDetailsContainer>

        <RightContainer main={false}>
          <RemoveButtonContainer onClick={handleOpenModal}>
            <Dots />
          </RemoveButtonContainer>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <ModalContainer>
              <RemoveButtonContainer>
                <RemoveItemParagraph>Remove</RemoveItemParagraph>
              </RemoveButtonContainer>
              <div
                style={{
                  height: '1px',
                  backgroundColor: 'lightgray',
                  width: '100%',
                }}
              />
              <CancelButton onClick={handleCloseModal}>Cancel</CancelButton>
            </ModalContainer>
          </Modal>
        </RightContainer>
      </div>
    </CartShippingCard>
  );
};

const CartShippingCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 0px 0px 10px 0px;
`;

const CardImageContainer = styled.div`
  padding-left: 16px;

  img {
    height: 80px;
    border-radius: 8px;
  }
`;

const CardProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;

  h1 {
    font-size: 24px;
    font-weight: 700;
  }
`;

const CardProductDetailsParagraph = styled.div<{ color?: string }>`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 0px 12px;
  color: ${({ color }) => color};

  span {
    font-size: 12px;
  }
`;

const CardProductDealsContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

const RightContainer = styled.div<{ main: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  margin-right: 8px;
  background-color: ${({ main }) =>
    main ? theme.target.greyLight : 'transparent'};
`;

const Dots = styled(BiDotsHorizontalRounded)`
  position: absolute;
  font-size: 32px;
  right: 7px;
  color: ${theme.target.grey};
`;

const ModalContainer = styled.div`
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${theme.samsclub.white};
`;

const RemoveButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  width: 100%;
`;

const RemoveItemParagraph = styled.p`
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 12px 0px;
  font-weight: 600;
  color: ${theme.samsclub.red?.dark};
`;

const CancelButton = styled.p`
  margin-top: 20px;
  color: ${theme.target.primary};
  font-weight: 600;
  font-size: 20px;
  color: ${theme.samsclub.blue?.steelblue};
`;

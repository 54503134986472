import React from "react";

import styled from "@emotion/styled";
import { SimilarProducts } from "../SimilarProducts";
import { IProduct } from "../../types";

export const PopularProducts = ({
  products,
  className,
  title,
}: {
  products: IProduct[];
  className?: string;
  title: string | undefined;
}) => {
  return (
    <PopularProductsStyled>
      <div style={{ paddingLeft: "12px" }}>
        <StyledSectionTitle>{title}</StyledSectionTitle>
      </div>
      <div>
        <SimilarProducts className={className} products={products} />
      </div>
    </PopularProductsStyled>
  );
};

const PopularProductsStyled = styled.div`
  background: #ffffff;
  padding: 8px 0px;

  ::-webkit-scrollbar {
    display: none;
  }

  .header {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 112px;
    }
  }
`;

const StyledSectionTitle = styled.p`
  display: flex;
  margin: 0px 0px 8px 0px;
  font-size: 18px;
  font-weight: 700;
`;

export const actions = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  TOGGLE_INLINE_FILTERS: "TOGGLE_INLINE_FILTERS",
  ADD_FILTER: "ADD_FILTER",
  ADD_SEARCH_FILTER: "ADD_SEARCH_FILTER",
  REMOVE_SEARCH_FILTER: "REMOVE_SEARCH_FILTER",
  ADD_PRODUCTS_SORTING: "ADD_PRODUCTS_SORTING",
  REMOVE_PRODUCTS_SORTING: "REMOVE_PRODUCTS_SORTING",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  CHECKOUT: "CHECKOUT",
  SET_PRODUCT_LENGTH: "SET_PRODUCT_LENGTH",
  SET_TERM_ID: 'SET_TERM_ID',
};

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../../theme';
export const Stepper = ({ newAmount, handleAddToCartChange }: any) => {
  return (
    <>
      <IconButtonStyled
        onClick={() => {
          handleAddToCartChange('minus');
        }}
      >
        <FontAwesomeIcon className="expand" icon={faMinus} />
      </IconButtonStyled>
      <span>{newAmount}</span>
      <IconButtonStyled
        reverse="rotate"
        onClick={() => {
          handleAddToCartChange('plus');
        }}
      >
        <FontAwesomeIcon className="expand" icon={faPlus} />
      </IconButtonStyled>
    </>
  );
};
const IconButtonStyled = styled(IconButton)<{ reverse?: string }>`
  color: ${theme.samsclub.white};
`;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import { motion, AnimatePresence } from "framer-motion";
import { VariantsFilter } from "../Filters/VariantsFilter";
import { BrandsFilterModal } from "../Filters/BrandsFilter";
import { FiltersMenuStyled } from "./styles";
import { useFiltersMenu } from "./useFiltersMenu";
import { IProduct } from "../../types";
import { Sorting } from "../Filters/Sorting";
import { PriceRangeFilter } from "../Filters/PriceRangeFilter";
import { RatingFilter } from "../Filters/RatingFilter";
import { useQuery } from "@apollo/client";
import { GET_LOCALIZED } from "../../queries";
import { useNavigate, useParams } from "react-router-dom";
import { useConfiguration } from "../../hooks/useConfiguration";
import { Slide } from "@mui/material";
import { useAppContext } from "../../context";
import { SlArrowLeft } from "react-icons/sl";
import { MdOutlineArrowBackIosNew, MdOutlineClose } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { CategoriesFilterModal } from "../Filters/CategoriesFilter";

export const FiltersMenu = ({
  productsBeforeFiltering,
  isModal,
  handleClose,
  categoryName,
}: any) => {
  const [activeFilter, setActiveFilter] = useState(null);

  const { isOpen, handleReset } = useFiltersMenu();
  const { storeId = "", harvestId = "" } = useParams();
  const { LanguageVersion } = useConfiguration({ storeId, harvestId });
  const { data: translation } = useQuery(GET_LOCALIZED, {
    variables: { defaultLanguage: LanguageVersion },
  });
  const { value } = useAppContext();
  const productCount = value.productLength;

  const filters = [
    { name: "Sort By", component: <Sorting buttonsOff={false} /> },
    {
      name: "Price",
      component: (
        <PriceRangeFilter
          buttonsOff={false}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
    { name: "Rating", component: <RatingFilter buttonsOff={false} /> },
    { name: "Variants", component: <VariantsFilter buttonsOff={false} /> },
    {
      name: "Brand",
      component: (
        <BrandsFilterModal
          buttonsOff={false}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
    {
      name: "Categories",
      component: (
        <CategoriesFilterModal
          buttonsOff={false}
          productsBeforeFiltering={productsBeforeFiltering}
        />
      ),
    },
  ];

  const handleFilterSelect = (filterName: any) => {
    setActiveFilter(filterName);
  };

  const handleBackToFilters = () => {
    setActiveFilter(null);
  };

  const slideInAnimation = {
    hidden: { x: "100%" },
    show: {
      x: 0,
      transition: { type: "spring", stiffness: 300, damping: 30 },
    },
    exit: {
      x: "100%",
      transition: { duration: 0.2 },
    },
  };

  const activeFilterComponent = filters.find((f) => f.name === activeFilter);

  return (
    <FiltersMenuStyled isInlineFilters={!!activeFilter}>
      <div className="filter-header">
        {activeFilter ? (
          <div className="active-filter-header">
            <MdOutlineArrowBackIosNew
              className="back-icon"
              onClick={handleBackToFilters}
            />
            <span>{activeFilter}</span>
          </div>
        ) : (
          <>
            <MdOutlineClose onClick={handleClose} />
            <span>All Filters</span>
            <motion.span
              className="reset"
              onClick={handleReset}
              whileTap={{ scale: 0.9 }}
            >
              {translation?.getLocalization.Clear}
            </motion.span>
          </>
        )}
      </div>

      {activeFilter && activeFilterComponent && (
        <Slide direction="left" in={true} unmountOnExit timeout={400}>
          <div>{activeFilterComponent.component}</div>
        </Slide>
      )}

      {!activeFilter && (
        <div className="filters">
          {filters.map((filter, index) => (
            <div
              key={index}
              className="filter"
              onClick={() => handleFilterSelect(filter.name)}
            >
              <span>{filter.name}</span>
              <IoIosArrowForward />
            </div>
          ))}
        </div>
      )}
      <div className="filter-footer">
        <Button
          fullWidth
          variant="contained"
          className="confirm-button"
          onClick={handleClose}
        >
          {translation?.getLocalization.ViewResults}
        </Button>
      </div>
    </FiltersMenuStyled>
  );
};

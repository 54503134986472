import { useEffect, useRef, useState } from "react";
import { IProduct } from "../../../../types";
import styled from "@emotion/styled";
import { StepperElement } from "./StepperElement";
import { theme } from "../../../../theme";
interface ICardOptionsSection {
  product: IProduct;
  setShowModal: (show: boolean) => void;
  setSelectedProduct: (product: IProduct | null) => void;
  setIsLoading: (loading: boolean) => void;
  isLast: boolean;
}

export const CardOptionsSection = ({ product }: ICardOptionsSection) => {
  const [isStepperVisible, setIsStepperVisible] = useState<boolean>(false);
  const stepperRef = useRef<HTMLDivElement>(null);
  const cartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        stepperRef.current &&
        !stepperRef.current.contains(event.target as Node) &&
        cartRef.current &&
        !cartRef.current.contains(event.target as Node)
      ) {
        setIsStepperVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleStepperVisibility = () => {
    setIsStepperVisible(!isStepperVisible);
  };

  const discountPriceDifference = product.RegularPrice - product.Price;

  return (
    <CardOptionsContainer ref={cartRef}>
      <div>
        {/* <StepperElement
          product={product}
          isAmount={true}
          productInCart={product}
        /> */}
        {/* 
        <PriceContainer>
          <PriceRow>
            {product.Price !== 0 && <OldPrice>${product.Price}</OldPrice>}
            <RegularPrice>${product.RegularPrice}</RegularPrice>
          </PriceRow>

          {product.Price !== 0 && (
            <DiscountText>
              You save ${discountPriceDifference.toFixed(2)}
            </DiscountText>
          )}
        </PriceContainer> */}
      </div>
    </CardOptionsContainer>
  );
};

const CardOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PriceContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 8px;
  margin-right: 12px;
  margin-bottom: 16px;
`;

const OldPrice = styled.p`
  text-decoration: line-through;
  font-size: 14px;
`;

const RegularPrice = styled.p`
  font-size: 16px;
`;

const DiscountText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.samsclub.red?.dark};
`;

const CompactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 12px 12px 108px;
`;

const AmountContainer = styled.div`
  display: flex;
  border: 2px solid grey;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;

const AmountText = styled.p`
  font-weight: 600;
`;

const CompactPriceContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
`;
